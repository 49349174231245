import React from "react";
import { Box, Button, Grid, } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Actions = (props) => {
    const {
        setIsOpen,
    } = props;
    const classes = useStyles();


    return (
        <Box>
            <Grid container spacing={ 1 }>
                <Grid item>
                    <Button
                        className={ classes.button }
                        variant="contained"
                        onClick={ () => setIsOpen( true ) }
                    >
                        Взять заказ
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


const useStyles = makeStyles( () => ( {

    button: {
        height: 45,
        padding: "0 32px",
        borderRadius: 4,

        fontSize: 14,
        lineHeight: "21px",
        fontWeight: "600",

        "&:hover": {
            backgroundColor: "#84B92C",
            color: "#FFFFFF"
        }
    }

} ) );

export default Actions
