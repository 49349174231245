import React, { useRef, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AttachFileOutlined as AttachFileIcon, Delete as DeleteIcon, Upload as UploadIcon } from '@mui/icons-material';
import { Formik } from 'formik';
import { convertorToCbm, convertorToPiece, getBase64 } from "../../../../../../helper/convertor";
import { Notification, NotificationTypes } from "../../../../../../common/Notification";
import { numberFormat } from "../../../../../../common/Formater";
import { makeStyles } from "@mui/styles";

let timeoutChangeValue = null;

const DialogPartialReturnForm = (props) => {
    const {
        initialValues,
        isOpen,

        handleClose,
        onPartialReturn,
    } = props;

    const classes = useStyles();
    const [ isLoadingImage, setIsLoadingImage ] = useState( false );
    const [ activeStep, setActiveStep ] = useState( 0 );

    const initVal = {
        photo: [],
        orderItems: initialValues.orderItems.map( val => {
            return {
                ...val,
                partialReturnValue: 0,
                partialReturnPiece: 0,
                partialReturnCbm: 0,
                partialReturnIsActive: false,
            };
        } ),
    };

    const refFormik = useRef( null );

    const onSubmit = async (form) => {
        const activeToReturnItems = form.orderItems.filter( item => item.partialReturnIsActive );
        const returnItems = activeToReturnItems.filter( item => item.partialReturnPiece > 0 );
        if (activeToReturnItems.length > 0) {
            if (returnItems.length > 0) {
                if (form.photo.length > 0) {
                    let photosBase64 = [];
                    for await (let photo of form.photo) {
                        const photoBase64 = await getBase64( photo.file ).then( res => res );
                        photosBase64.push( photoBase64 );
                    }
                    const newForm = {
                        productsPhotosInBase64: photosBase64,
                        returnItems: returnItems.map( item => {
                            return {
                                orderItemId: item.id,
                                quantity: item.partialReturnCbm,
                            }
                        } ),
                    };
                    onPartialReturn( newForm );
                    handleClose();
                } else {
                    Notification( {
                        type: NotificationTypes.error,
                        message: "Приложите фотографии товаров",
                    } );
                }
            } else {
                Notification( {
                    type: NotificationTypes.error,
                    message: "Введите количество возвращаемого товара",
                } );
            }
        } else {
            Notification( {
                type: NotificationTypes.error,
                message: "Выберите товар который хотите вернуть",
            } );
        }
    };

    const handleCloseModal = () => {
        setActiveStep( 0 );
        const resetFormik = refFormik.current.values;
        resetFormik.photo = '';
        resetFormik.orderItems.forEach( form => {
            form.partialReturnIsActive = false;
            form.partialReturnCbm = 0;
            form.partialReturnPiece = 0;
            form.partialReturnValue = 0;
        } )
        refFormik.current.setValues( resetFormik );
        handleClose();
    }

    const handleChangeSwitch = (e, index) => {
        const { checked } = e.target;
        const newForm = refFormik.current.values;

        newForm.orderItems[index].partialReturnIsActive = checked;

        refFormik.current.setValues( newForm );
    };

    const handleChangeImage = (event) => {
        setIsLoadingImage( true );
        const file = event?.target.files?.[0] || null;

        let newForm = refFormik.current?.values || {};
        let image_id = {
            file: '',
            source: '',
        };
        image_id.file = file;
        image_id.source = URL.createObjectURL( file );
        newForm.photo = [ ...newForm.photo, image_id ];
        refFormik.current.setValues( newForm );
        setIsLoadingImage( false );
    };

    const handleDeleteImage = (index) => {
        const newForm = refFormik.current.values;
        newForm.photo.splice( index, 1 );

        refFormik.current.setValues( newForm );
    };

    const handleChangeCbmInput = (e, index, coefficient) => {
        const cbmInPiece = coefficient;
        const pieceToCbm = 1 / coefficient;
        const { value } = e.target;
        const newForm = refFormik.current.values;

        if (value < ( newForm.orderItems[index].quantity * pieceToCbm )) {
            const piece = Math.floor( value * cbmInPiece );

            newForm.orderItems[index].partialReturnCbm = value;
            newForm.orderItems[index].partialReturnPiece = Number( piece );
        } else {
            newForm.orderItems[index].partialReturnCbm = 0;
            newForm.orderItems[index].partialReturnPiece = 0;
        }

        refFormik.current.setValues( newForm );
    };

    const handleChangePieceInput = (e, index, coefficient) => {
        const { value } = e.target;
        const newForm = refFormik.current.values;
        const totalQuantity = newForm.orderItems.reduce( (acc, curr) => {
            return acc + convertorToPiece( curr.quantity, curr?.product?.pieceToCbmConversionFactor || 0 );
        }, 0 );
        const totalReturnQuantity = newForm.orderItems.reduce( (acc, curr) => {
            return acc + +curr.partialReturnPiece;
        }, 0 );

        if (newForm.orderItems.length > 1) {
            if (value < totalQuantity - totalReturnQuantity) {
                clearTimeout( timeoutChangeValue );
                if (Number( value ) >= 1 && Number( value ) <= convertorToPiece( newForm.orderItems[index].quantity, newForm.orderItems[index]?.product?.pieceToCbmConversionFactor || 0 )) {
                    const cbm = convertorToCbm( value, coefficient );

                    newForm.orderItems[index].partialReturnValue = +value;
                    newForm.orderItems[index].partialReturnCbm = cbm;
                    timeoutChangeValue = setTimeout( () => {
                        newForm.orderItems[index].partialReturnPiece = Number( value );
                        refFormik.current.setValues( newForm );
                    }, 1000 )
                } else {
                    newForm.orderItems[index].partialReturnValue = 0;
                    newForm.orderItems[index].partialReturnPiece = 0;
                    newForm.orderItems[index].partialReturnCbm = 0;
                }

                refFormik.current.setValues( newForm );
            } else {
                Notification( {
                    type: NotificationTypes.warning,
                    message: `Должно оставаться минимум 1шт.`
                } );
                newForm.orderItems[index].partialReturnValue = 0;
                newForm.orderItems[index].partialReturnPiece = 0;
                newForm.orderItems[index].partialReturnCbm = 0;
                refFormik.current.setValues( newForm );
            }
        } else {
            if (Number( value ) >= 1 && Number( value ) < convertorToPiece( newForm.orderItems[index].quantity, newForm.orderItems[index]?.product?.pieceToCbmConversionFactor || 0 )) {
                const cbm = convertorToCbm( value, coefficient );

                newForm.orderItems[index].partialReturnValue = +value;
                newForm.orderItems[index].partialReturnCbm = cbm;
                newForm.orderItems[index].partialReturnPiece = Number( value );
            } else {
                Notification( {
                    type: NotificationTypes.warning,
                    message: `Должно оставаться минимум 1шт.`
                } );
                newForm.orderItems[index].partialReturnValue = 0;
                newForm.orderItems[index].partialReturnPiece = 0;
                newForm.orderItems[index].partialReturnCbm = 0;
            }

            refFormik.current.setValues( newForm );
        }
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece( orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( orderItem.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={ isOpen }

            onClose={ handleCloseModal }
        >
            <DialogTitle>Частичный возврат</DialogTitle>

            <DialogContent>
                <Formik
                    initialValues={ initVal }
                    innerRef={ refFormik }

                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;
                        return (
                            <>
                                <Stepper activeStep={ activeStep } orientation="vertical">
                                    <Step>
                                        <StepLabel>
                                            Выберите товары которые подлежат возврату
                                        </StepLabel>
                                        <StepContent>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width="40%">Наименование</TableCell>
                                                        <TableCell>Кол-во</TableCell>
                                                        <TableCell>Кол-во к возврату</TableCell>
                                                        <TableCell/>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { values.orderItems.map( (val, index) => (
                                                        <TableRow key={ `item-${ val.id }` }>
                                                            <TableCell>{ val.productName }</TableCell>
                                                            <TableCell>{ renderQuantity( val ) }</TableCell>
                                                            <TableCell>
                                                                <Grid container spacing={ 2 }>
                                                                    <Grid item xs={ 12 }>
                                                                        <Grid container alignItems="flex-end"
                                                                              spacing={ 1 }>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    sx={ {
                                                                                        width: "50px",
                                                                                        height: "30px",
                                                                                        "& div": {
                                                                                            height: "30px",
                                                                                        },
                                                                                        "& input": {
                                                                                            padding: "0px 6px",
                                                                                            textAlign: "center",
                                                                                        }
                                                                                    } }
                                                                                    disabled={ !val.partialReturnIsActive }
                                                                                    value={ val.partialReturnValue }
                                                                                    name="partialReturnValue"

                                                                                    onChange={ (e) => handleChangePieceInput( e, index, val?.product?.pieceToCbmConversionFactor || 0 ) }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    variant="h6">шт.</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={ 12 }>
                                                                        <Grid container alignItems="flex-end"
                                                                              spacing={ 1 }>
                                                                            <Grid item>
                                                                                <TextField
                                                                                    sx={ {
                                                                                        width: "50px",
                                                                                        height: "30px",
                                                                                        "& div": {
                                                                                            height: "30px",
                                                                                        },
                                                                                        "& input": {
                                                                                            padding: "0px 6px",
                                                                                            textAlign: "center",
                                                                                        }
                                                                                    } }
                                                                                    disabled
                                                                                    value={ val.partialReturnCbm }
                                                                                    name="partialReturnCbm"

                                                                                    onChange={ (e) => handleChangeCbmInput( e, index, val?.product?.pieceToCbmConversionFactor || 0 ) }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography
                                                                                    variant="h6">м³</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControlLabel
                                                                    control={ <Switch
                                                                        checked={ val.partialReturnIsActive || false }/> }
                                                                    label="Возврат"
                                                                    onChange={ (e) => handleChangeSwitch( e, index ) }/>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) ) }
                                                </TableBody>
                                            </Table>
                                        </StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            Приложите фотографию товаров
                                        </StepLabel>
                                        <StepContent>
                                            { values.photo.length > 0 &&
                                                <>
                                                    { values.photo.map( (photo, index) => (
                                                        <>
                                                            <Box
                                                                key={ `partial-return-fill-image-${ photo?.file?.name || 'fileName' }-${ index }` }

                                                            >
                                                                <ReturnImage
                                                                    imageId={ photo }
                                                                    indexPhoto={ index }
                                                                    viewImage
                                                                />
                                                                <ReturnImage
                                                                    imageId={ photo }
                                                                    indexPhoto={ index }
                                                                    viewFile
                                                                    handleDeleteImage={ handleDeleteImage }
                                                                />
                                                            </Box>
                                                        </>
                                                    ) ) }
                                                </>
                                            }

                                            <LoadingButton
                                                className={ classes.uploadButton }
                                                loading={ isLoadingImage }
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                <label style={ {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                } }>
                                                    <UploadIcon sx={ { marginRight: "5px" } }/>
                                                    Выберите файл
                                                    <input
                                                        width="100%"
                                                        type="file"
                                                        name="image_id"
                                                        accept="image/*"
                                                        hidden
                                                        onChange={ (event) => handleChangeImage( event ) }
                                                    />
                                                </label>
                                            </LoadingButton>
                                        </StepContent>
                                    </Step>
                                </Stepper>

                                <DialogActions>
                                    <>
                                        { activeStep === 1 && (
                                            <Button
                                                variant="outlined"
                                                onClick={ () => setActiveStep( prev => prev - 1 ) }
                                            >
                                                Назад
                                            </Button>
                                        ) }
                                        <Button variant="outlined" onClick={ handleCloseModal }>Отменить</Button>
                                        { activeStep === 0
                                            ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={ () => setActiveStep( prev => prev + 1 ) }
                                                >
                                                    Дальше
                                                </Button>
                                            )
                                            : (
                                                <Button variant="contained"
                                                        onClick={ handleSubmit }>Отправить</Button>
                                            )
                                        }
                                    </>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const ReturnImage = React.memo( (props) => {
    const {
        imageId,
        indexPhoto,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;

    return ( <>
        { Boolean( viewImage )
            && (
                <Box
                    width="100%"
                    height={ 500 }
                    sx={ {
                        backgroundColor: "rgba(132,185,44, .2)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        boxSizing: "border-box",
                    } }
                    mb={ 2 }
                >
                    <img src={ imageId.source } style={ {
                        display: "block", width: "100%", height: "100%", objectFit: "contain", borderRadius: 5,
                    } } alt=""/>
                </Box>
            )
        }
        { Boolean( viewFile )
            && (
                <Box display="flex" mb={ 2 }>
                    <Box
                        sx={ {
                            flex: 8,
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            background: "#84b92c",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "14px",
                            justifyContent: "center",
                            marginRight: "5px"
                        } }
                    >
                        <AttachFileIcon
                            sx={ {
                                marginRight: "5px",
                            } }
                        />
                        { imageId?.file?.name }
                    </Box>
                    <IconButton
                        color="error"

                        onClick={ () => handleDeleteImage( indexPhoto ) }
                    >
                        <Tooltip title="Удалить файл">
                            <DeleteIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>
            )
        }
    </> );
} );

const useStyles = makeStyles( {
    uploadButton: {
        "&.MuiButton-root": {
            padding: 0,

            "& > label": {
                padding: 0,
                height: "100%",
            },
        },
    },
} );

export default DialogPartialReturnForm;
