import React, { Component } from "react";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import {
    DialogAddStore as DialogAddStoreComponent,
    DialogExecutingOrders as DialogExecutingOrdersComponent,
    Table as TableComponent,
} from "./components";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";
import { DialogConfirmation } from "../../../../components";

const initialFilter = {
    sort: "",
};

class MyStores extends Component {
    constructor(props) {
        super( props );

        this.state = {
            stores: [],
            executingOrders: [],
            alertMessage: "",

            filter: {
                ...initialFilter,
            },
            pagination: {
                page: 1, totalPage: 1,
            },

            isOpenAddStore: false,
            isOpenAlert: false,
            isOpenExecutingOrders: false,

            isLoading: true,
            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getStores();
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse( locationSearch, {
            arrayFormat: "bracket",
        } );
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch,
        };
        let pagination = {
            ...this.state.pagination,
            page: page,
        };

        await this.setState( {
            filter, pagination,

            initOpenFilter: Object.keys( parseSearch || {} ).length > 0,
        } );
    };

    // Логика получения складов
    getStores = async () => {
        this.setState( { isLoading: true } );

        const filter = this.getFilters();
        const { stores, totalCount } = await agent
            .get( `/stores/get-by-user/${ this.props.user.id }${ filter }&limit=20` )
            .then( (res) => {
                return {
                    stores: res.data.stores || [],
                    totalCount: res.data.count,
                };
            } )
            .catch( (err) => {
                return {
                    stores: [],
                    totalCount: 0,
                };
            } );

        let pagination = { ...this.state.pagination };
        pagination.totalPage = Math.ceil( totalCount / 20 ) || 1;

        this.setState( {
            isLoading: false, pagination, stores,
        } );
    };

    // Логика создания склада
    createStore = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите создать склад?",
                acceptButtonTitle: "Да, создать",
                acceptButtonAction: this.createStore.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.post( `/stores`, form )
            .then( (res) => res.data )
            .catch( (err) => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при создании склада",
                type: NotificationTypes.error,
            } );

            return null;
        }

        await this.getStores();
        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Склад успешно создан",
            type: NotificationTypes.success,
        } );
    };

    // Удаление склада
    deleteStore = async (id, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите удалить склад?",
                acceptButtonTitle: "Да, удалить",
                acceptButtonAction: this.deleteStore.bind( this, id, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.delete( `/stores/${ id }` )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: response.error?.data?.message || "Ошибка при удалении склада",
                type: NotificationTypes.error,
            } );

            return null;
        }

        if (!response.success) {
            const executingOrders = response.executingOrders.map( order => order.id );
            this.setState( {
                executingOrders,
                isOpenExecutingOrders: true,
                isShowBackdrop: false,
            } );

            const message = `Склад нельзя удалить, на складе выполняются заказы`;

            Notification( {
                message: message,
                type: NotificationTypes.error,
            } );

            return null;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getStores();

        Notification( {
            message: "Склад успешно удален",
            type: NotificationTypes.success,
        } );
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let string = [ `page=${ pagination.page }` ];
        Object.keys( filter ).map( (key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push( `${ key }=${ value }` );
            }
        } );

        window.history.replaceState( null, null, `/my-stores?${ string.join( "&" ) }` );

        return `?${ string.join( "&" ) }`;
    };
    changeFilter = async (filter, isFastStart) => {
        await this.setState( { filter } );

        if (!isFastStart) {
            return null;
        }

        await this.getUsers();
    };
    changePagination = async (pagination) => {
        await this.setState( { pagination } );
        await this.getStores();
    };
    resetFilter = async () => {
        await this.setState( { filter: { ...initialFilter } } );
        await this.getUsers();
    };

    _routeStore = (order) => {
        this.props.history.push( `/my-stores/${ order.id }` );
    };

    // Открытие формы создания склада
    setIsOpenAddStore = (isOpen) => {
        this.setState( { isOpenAddStore: isOpen } );
    };
    // Закрытие формы создания склада
    handleCloseAddStore = () => {
        this.setIsOpenAddStore( false );
    };

    // Открытие модалки с уведомлением о выполняющихся заказов
    setIsOpenExecutingOrders = (isOpen) => {
        this.setState( { isOpenExecutingOrders: isOpen } );
    };
    // Закрытие модалки с уведомлением о выполныющихся заказов
    handleCloseExecutingOrders = () => {
        this.setIsOpenExecutingOrders( false );
    };

    render() {
        const {
            stores,
            executingOrders,

            filter,
            pagination,

            isOpenAddStore,
            isOpenExecutingOrders,

            isLoading,
            isShowBackdrop
        } = this.state;

        return ( <>
            <Box mb={ 4 }>
                <Typography variant="h1" textAlign="left">Мои склады</Typography>
            </Box>
            {/* <FilterComponent
                    filter={filter}
                /> */ }
            <div
                style={ {
                    display: "flex", justifyContent: "right", marginBottom: "8px",
                } }
            >
                <Button
                    sx={ {
                        textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1,
                    } }
                    size="small"
                    variant="contained"
                    onClick={ this.setIsOpenAddStore.bind( this, true ) }
                >
                    Добавить склад
                </Button>
            </div>
            <TableComponent
                data={ stores }
                filter={ filter }
                pagination={ pagination }
                isLoading={ isLoading }
                onChangePagination={ this.changePagination }
                routeStore={ this._routeStore }
                onDeleteStore={ this.deleteStore }
            />

            <DialogAddStoreComponent
                isOpen={ isOpenAddStore }
                onClose={ this.handleCloseAddStore }
                onCreate={ this.createStore }
            />
            {/*<AddStoreModalCompoonent*/ }
            {/*    createStore={this.createStore}*/ }
            {/*    handleClose={this.handleClose}*/ }
            {/*    isOpen={isOpenModal}*/ }
            {/*/>*/ }

            <DialogExecutingOrdersComponent
                executingOrders={ executingOrders }
                isOpen={ isOpenExecutingOrders }
                onClose={ this.handleCloseExecutingOrders }
            />

            <DialogConfirmation
                ref={ this.refDialogConfirmation }
            />

            <Backdrop open={ isShowBackdrop }>
                <CircularProgress color="white"/>
            </Backdrop>
        </> );
    }
}

export default MyStores;
