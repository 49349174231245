// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Accreditation from './Accreditation';

export default compose(
    connect(
        state => ( {} ),
        dispatch => ( {} ),
    ),
)( Accreditation );
