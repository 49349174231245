import React from "react";
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { EditRounded as EditIcon } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import { Map } from "../../../../../../components";

const Information = (props) => {
    const {
        store,
        onEditReserveStatus,
        onOpenEditStore
    } = props;
    const classes = useStyles();
    const [ isShowMap, setIsShowMap ] = React.useState( false );
    const coords = [ store.addressLat, store.addressLong ];

    const handleChangeReserve = ({ target }) => {
        const { checked } = target;
        const newForm = {
            name: store.name,
            description: store.description,
            address: store.address,
            addressLat: store.addressLat,
            addressLong: store.addressLong,
            reserve: checked,
        };

        onEditReserveStatus( newForm );
    }

    return (
        <Box className={ classes.root }>
            <Box className={ classes.rowContent }>
                <Typography
                    variant="h3"
                    sx={ { fontWeight: "600" } }
                >
                    Информация о складе
                </Typography>
                <IconButton
                    onClick={ onOpenEditStore.bind( this, true ) }
                >
                    <EditIcon/>
                </IconButton>
            </Box>
            <Table className={ classes.table }>
                <TableBody>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Название склада
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            { store.name || "—" }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Описание склада
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            { store.description || "—" }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Резерв
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            <Switch
                                checked={ store.reserve }
                                onChange={ handleChangeReserve }
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Адрес
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            <Grid container alignItems="center" spacing={ 1 }>
                                <Grid item>
                                    { store.address || "—" }
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={ classes.tableButton }
                                        variant='outlined'

                                        onClick={ () => setIsShowMap( !isShowMap ) }
                                    >
                                        { isShowMap ? "Свернуть карту" : "Развернуть карту" }
                                    </Button>
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Широта
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            { store.addressLat || "—" }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={ classes.tableLabel }>
                            Долгота
                        </TableCell>
                        <TableCell className={ classes.tableMessage }>
                            { store.addressLong || "—" }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Collapse in={ isShowMap } timeout="auto">
                <Box height={ 420 } mt={ 2 }>
                    <Map
                        center={ coords }
                        coords={ coords }
                    />
                </Box>
            </Collapse>
        </Box>
    )
}

const useStyles = makeStyles( () => ( {
    root: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px"
    },

    rowContent: {
        display: "flex",
        alignItems: 'center',
        gap: 16,
    },

    table: {
        boxShadow: "none",
        borderRadius: 0,
    },
    tableLabel: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#464646",
        },
    },
    tableMessage: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "black",
            fontWeight: "600",
        },
    },
    tableButton: {
        height: 30,
        fontSize: 12,
        padding: "0 12px",
        borderRadius: 4
    },
} ) );

export default Information
