import React, { useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";


const initialState = {
    store: '',
}

const DialogSetStore = (props) => {
    const {
        isOpen,
        takeWorkOrder,
        order,
        stores,

        setNewOrder,
        handleClose,
    } = props
    const formik = useRef( null );

    const handleCloseModal = () => {
        handleClose();
        formik.current.setValues( {} );
    }

    const onSubmit = async () => {
        const newOrder = order;
        newOrder.storeId = formik.current.values.store;
        await setNewOrder( newOrder );
        takeWorkOrder();
        handleCloseModal();
    }

    return ( <Dialog
        fullWidth
        maxWidth="md"
        open={ isOpen }

        onClose={ handleCloseModal }
    >
        <DialogTitle>
            <Typography variant="h3">
                Заказ № { order?.id }
            </Typography>
        </DialogTitle>

        <DialogContent>
            <Formik
                innerRef={ formik }
                initialValues={ initialState }
                validationSchema={ validateSchema }
                onSubmit={ onSubmit }
            >
                { (props) => {
                    const {
                        values, errors, touched, handleChange, handleSubmit
                    } = props;

                    return ( <>
                        <Box pt={ 1 }>
                            <Box mb={ 2 }>
                                <FormControl fullWidth error={ touched.store && Boolean( errors.store ) }>
                                    <InputLabel>Склад</InputLabel>
                                    <Select
                                        name="store"
                                        label="Склад"
                                        value={ values.store }
                                        onChange={ handleChange }
                                    >
                                        { stores.map( (store) => ( <MenuItem value={ store.id }>
                                            { store.name }
                                        </MenuItem> ) ) }
                                    </Select>
                                </FormControl>
                                { Boolean( touched.store && Boolean( errors.store ) ) && (
                                    <FormHelperText error variant="filled">
                                        { touched.store && errors.store }
                                    </FormHelperText> ) }
                            </Box>
                        </Box>
                        <DialogActions>
                            <Box px={ 2 }>
                                <Grid container justifyContent="flex-end" spacing={ 1 }>
                                    <Grid item>
                                        <Button variant="outlined" onClick={ handleCloseModal }
                                                sx={ {
                                                    textTransform: "initial",
                                                    borderRadius: "4px",
                                                    padding: "4px 24px"
                                                } }>
                                            Отменить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={ handleSubmit }>
                                            Взять в работу
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogActions>
                    </> )

                } }
            </Formik>
        </DialogContent>
    </Dialog> );
};


const validateSchema = Yup.object().shape( {
    store: Yup.string().required( 'Обязательное поле' ),
} )

export default DialogSetStore;
