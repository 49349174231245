import React from "react";
import {
    Box,
    Button,
    Grid,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import { numberFormat } from "../../../../../../common/Formater";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import { providerPrice } from "../../../../../../helper/providerPrice";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,
        settings,
        visibleColumns,

        isLoading,

        onChangePagination,
        onChangeFilter,
        onOpenDetailProducts,
        onTakeWorkOrder,
        openModal,

        routeOrder
    } = props;
    const classes = useStyles();

    const handleClickRow = (row, event) => {
        const isDisableRoute = Boolean( event.target.closest( ".disable-route" ) );
        if (isDisableRoute) {
            return
        }

        routeOrder( row );
    }
    const handleChangePage = (event, page) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination( newPagination )
    }
    const handleClickButtonTakeWorkOrder = (row) => {
        onTakeWorkOrder( row );
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = { ...filter };
        newFilter[name] = value;

        onChangeFilter( newFilter, true );
    };

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }
    const _getDeliveryDate = (row) => {
        const date = moment( row.deliveryDate ).format( "DD.MM.YYYY" );
        const timesDelivery = deliveryTime( settings );
        const timespan = timesDelivery.filter( time => time.value === row.deliveryTimespan );

        return `${ date } (${ timespan[0]?.label || "Время не указано" })`
    }
    const _getDeliveryAddress = (row) => {
        const deliveryMethod = row.deliveryMethod;

        switch (deliveryMethod) {
            case "pickup":
                return "-";
            case "delivery":
                return row?.deliveryAddress || "Милицейский адрес не найден";
            default:
                return 'Милицейский адрес не найден';
        }
    }
    const _getOrderStatus = (row) => {
        return "Ожидается"
    }
    const _getOrderItems = (orderItems) => {
        return orderItems
            .map( (order) => {
                const productName = order?.productName || "";

                if (!productName) {
                    return;
                }

                return `${ productName } (${ order.quantity } м³)`
            } )
            .filter( (t) => !!t )
            .join( ";</br>" )
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <EnhancedTableHead
                            visibleColumns={ visibleColumns }
                            filter={ filter }
                            handleChangeFilter={ handleChangeFilter }
                        />
                        {/*<TableCell width="100px">№ заказа</TableCell>*/ }
                        {/*<TableCell>Срок выполнения</TableCell>*/ }
                        {/*<TableCell>Состав заказа (кол-во)</TableCell>*/ }
                        {/*<TableCell>Способ доставки</TableCell>*/ }
                        {/*<TableCell>Адрес доставки</TableCell>*/ }
                        {/*<TableCell align="right">Стоимость доставки</TableCell>*/ }
                        {/*<TableCell align="right">Стоимость заказа</TableCell>*/ }
                        {/*<TableCell align="right"/>*/ }
                    </TableRow>
                </TableHead>

                <TableBody>

                    { ( data || [] ).map( (row, index) => (
                        <Tooltip title={ `Открыть заказ №${ row.id }` } arrow>
                            <TableRow
                                key={ `row-${ index }` }
                                sx={ { cursor: "pointer" } }
                                hover

                                onClick={ (event) => handleClickRow( row, event ) }
                            >
                                <TableCell>
                                    { row.id }
                                </TableCell>
                                <TableCell>
                                    { _getDeliveryDate( row ) }
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        dangerouslySetInnerHTML={ {
                                            __html: _getOrderItems( row.orderItems || [] )
                                        } }
                                    />
                                </TableCell>
                                <TableCell>
                                    { _getDeliveryMethod( row.deliveryMethod ) }
                                </TableCell>
                                <TableCell>
                                    { _getDeliveryAddress( row ) }
                                </TableCell>
                                <TableCell align="right">
                                    { numberFormat( row.finalDeliveryPrice, 2, '.' ) } ₽
                                </TableCell>
                                <TableCell align="right">
                                    { numberFormat( providerPrice( row.sum, +settings.systemPercent ), 2, '.' ) } ₽
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Взять заказ в работу" arrow>
                                        {/*<Button*/ }
                                        {/*    variant="contained"*/ }
                                        {/*    size="small"*/ }
                                        {/*    className={`${classes.buttonTakeWorkOrder} disable-route`}*/ }
                                        {/*    onClick={(event) => handleClickButtonTakeWorkOrder(row, event)}*/ }
                                        {/*>*/ }
                                        {/*    Взять заказ*/ }
                                        {/*</Button>*/ }
                                        <Button
                                            variant="contained"
                                            size="small"
                                            className={ `${ classes.buttonTakeWorkOrder } disable-route` }
                                            onClick={ () => openModal( true, row ) }
                                        >
                                            Взять заказ
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </Tooltip>
                    ) ) }

                </TableBody>

            </Table>

            <Box mt={ 3 }/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={ Number( pagination.page ) }
                        count={ Number( pagination.totalPage ) }

                        onChange={ handleChangePage }
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>

        </>
    );
}

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${ col }`) {
            handleChangeFilter( "sortInfo", col );
        } else {
            handleChangeFilter( "sortInfo", `-${ col }` );
        }
    };
    return (
        <>
            { visibleColumns.map( column => (
                <TableCell
                    key={ column.id }
                    width={ column.width }
                >
                    { column.sortable
                        ? (
                            <TableSortLabel
                                classes={ {
                                    active: classes.activeSort,
                                    root: classes.sort,
                                } }
                                active={ filter.sortInfo === column.id || filter.sortInfo === `-${ column.id }` }
                                direction={ filter.sortInfo === column.id ? "desc" : "asc" }

                                onClick={ handleChangeSort.bind( this, column.id ) }
                            >
                                { column.label }
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ) ) }
        </>
    );
};

const useStyles = makeStyles( () => ( {
    buttonTakeWorkOrder: {
        textTransform: "initial",
        fontSize: 14,
        padding: "4px 12px",
        height: "auto",
        borderRadius: 8,
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },


    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },
} ) );

export default TableCustom
