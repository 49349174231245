import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import Map from "../../../../../../components/Map";
import clsx from "clsx";
import { convertorNumber } from "../../../../../../helper/convertor";

const MainOrderInformation = (props) => {
    const {
        order
    } = props;

    const classes = useStyles();
    const settings = useSelector( state => state.global.settings );
    const [ isShowMap, setIsShowMap ] = React.useState( false );
    const store = order?.store;

    const _deliveryDate = () => {
        const deliveryTimes = deliveryTime( settings );
        const date = moment( order?.deliveryDate ).format( "DD.MM.YYYY" );
        const time = deliveryTimes.filter( time => time.value === order.deliveryTimespan );

        return `${ date } (${ time[0]?.label || "Время не указано" })`;
    }

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }

    const _deliveryAddress = () => {
        return order?.deliveryAddress || "Милицейский адрес не найден";
    }

    return (
        <>
            <Box className={ classes.root }>
                <Typography className={ classes.title }>
                    Основная информация о заказе №{ order.id }
                </Typography>

                <Box className={ classes.content }>
                    <Box>
                        <Typography className={ classes.towLabel }>Дата/время доставки</Typography>
                        <Grid container spacing={ 2 } alignItems="center">
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { _deliveryDate() }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Typography className={ classes.towLabel }>Способ доставки</Typography>
                        <Grid container spacing={ 2 } alignItems="center">
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { _getDeliveryMethod( order.deliveryMethod ) }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    { order.deliveryMethod === 'delivery' && (
                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Адрес доставки</Typography>
                            <Grid container spacing={ 2 } alignItems="center">
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        { _deliveryAddress() }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={ classes.buttonChangeDeliveryDate }

                                        onClick={ setIsShowMap.bind( this, !isShowMap ) }
                                    >
                                        { isShowMap ? "Свернуть" : "Развернуть" } карту
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) }

                    <Box className={ classes.columnContent }>
                        <Typography className={ classes.storeInfoTitle }>Склад</Typography>
                        <Typography className={ classes.storeInfoValue }>
                            { store?.name }
                        </Typography>
                    </Box>

                    { order.confirmationCode && (
                        <Box className={ classes.columnContent }>
                            <Typography className={ classes.towLabel }>Код подтверждения</Typography>
                            <Typography className={ classes.towValue }>
                                { order.confirmationCode }
                            </Typography>
                        </Box>
                    ) }

                    { order.discount > 0 && (
                        <Box className={ classes.columnContent }>
                            <Typography className={ clsx( {
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            } ) }>Скидка</Typography>
                            <Typography className={ clsx( {
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            } ) }>
                                { convertorNumber( order.discount, 2 ) } ₽
                            </Typography>
                        </Box>
                    ) }

                    { order.adminDiscount > 0 && (
                        <Box className={ classes.columnContent }>
                            <Typography className={ clsx( {
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            } ) }>Скидка администратора</Typography>
                            <Typography className={ clsx( {
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            } ) }>
                                { convertorNumber( order.adminDiscount, 2 ) } ₽
                            </Typography>
                        </Box>
                    ) }

                    { order.providerComment && order.providerComment !== "" && (
                            <Box className={ classes.columnContent }>
                                <Box className={ classes.rowContent }>
                                    <Typography
                                        className={ classes.towLabel }
                                        sx={ {
                                            marginBottom: "0 !important",
                                        } }
                                    >
                                        Комментарий
                                    </Typography>
                                </Box>

                                <Typography mt={ 1 } className={ classes.towValue }>
                                    { order?.providerComment }
                                </Typography>
                            </Box>
                        )
                    }
                </Box>

                { order.deliveryMethod === "delivery" && (
                    <Collapse in={ isShowMap } timeout="auto" sx={ { marginTop: "20px" } }>
                        <Box className={ classes.content }>
                            <Box className={ classes.row } mb={ 1 }>
                                <Typography className={ classes.towLabel }>Широта</Typography>
                                <Typography className={ classes.towValue }>
                                    { order?.deliveryAddressLat || "-" }
                                </Typography>
                            </Box>

                            <Box className={ classes.row }>
                                <Typography className={ classes.towLabel }>Долгота</Typography>
                                <Typography className={ classes.towValue }>
                                    { order?.deliveryAddressLong || "-" }
                                </Typography>
                            </Box>
                        </Box>
                        <Box height={ 420 }>
                            <Map
                                center={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                                coords={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                            />
                        </Box>
                    </Collapse>
                ) }
            </Box>
        </>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 18
    },

    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)",

            "@media (max-width: 600px)": {
                marginTop: 9,
                marginBottom: 0,
                width: "100%",
            },
        },

        "@media (max-width: 600px)": {
            marginTop: -9,
            flexDirection: "column",
        },
    },


    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white"
    },

    store: {},
    storeTitle: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
        fontWeight: "500",
        marginBottom: 16
    },
    storeInfo: {},
    storeInfoTitle: {
        fontSize: 16,
        lineHeight: "16px",
        color: "black",
        opacity: 0.6,
        marginBottom: 8
    },
    storeInfoValue: {
        fontSize: 18,
        lineHeight: "20px",
        color: "black",
        fontWeight: "500"
    },
} );

export default MainOrderInformation;
