import React from 'react';
import { Box, Button, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { HelpOutlineRounded as HelpIcon, } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { formatPhone } from "../../../../../../helper/formatPhone";
import clsx from "clsx";

const ContactInformation = (props) => {
    const {
        order,
        adminsPhones,
    } = props;

    const classes = useStyles();

    return (
        <>
            <Box className={ classes.root }>
                <Typography className={ clsx( {
                    [classes.title]: true,
                    [classes.titleMargin]: true,
                } ) }>
                    Контактная информация
                </Typography>

                <Box className={ classes.content }>
                    { ( order.purchaserUser && order.purchaserUser.phone ) && (
                        <Box>
                            <Typography className={ classes.towLabel }>Телефон заказчика</Typography>

                            <Box className={ classes.rowContent }>
                                <Typography className={ classes.towValue }>
                                    <Tooltip title="Позвонить" arrow>
                                        <Link href={ `tel:+${ order?.purchaserUser?.phone }` }>
                                            { formatPhone( order?.purchaserUser?.phone ) }
                                        </Link>
                                    </Tooltip>
                                </Typography>

                                <Button
                                    href={ `https://t.me/+${ order?.purchaserUser?.phone }` }
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    sx={ {
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                        marginLeft: "15px"
                                    } }
                                >
                                    Написать в телеграм
                                </Button>
                            </Box>
                        </Box>
                    ) }

                    { ( order.courierUser && order.courierUser.phone ) && (
                        <Box>
                            <Typography className={ classes.towLabel }>Телефон экспедитора</Typography>

                            <Box className={ classes.rowContent }>
                                <Typography className={ classes.towValue }>
                                    <Tooltip title="Позвонить" arrow>
                                        <Link href={ `tel:+${ order.courierUser.phone }` }>
                                            { formatPhone( order.courierUser.phone ) }
                                        </Link>
                                    </Tooltip>
                                </Typography>

                                <Button
                                    href={ `https://t.me/+${ order.courierUser.phone }` }
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    sx={ {
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                        marginLeft: "15px"
                                    } }
                                >
                                    Написать в телеграм
                                </Button>
                            </Box>
                        </Box>
                    ) }

                    { ( order.driver && order.driver.phone ) && (
                        <Box>
                            <Typography className={ classes.towLabel }>Телефон водителя</Typography>

                            <Box className={ classes.rowContent }>
                                <Typography className={ classes.towValue }>
                                    <Tooltip title="Позвонить" arrow>
                                        <Link href={ `tel:+${ order.driver.phone }` }>
                                            { formatPhone( order.driver.phone ) }
                                        </Link>
                                    </Tooltip>
                                </Typography>

                                <Button
                                    href={ `https://t.me/+${ order.driver.phone }` }
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    sx={ {
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                        marginLeft: "15px"
                                    } }
                                >
                                    Написать в телеграм
                                </Button>
                            </Box>
                        </Box>
                    ) }
                </Box>
            </Box>

            { adminsPhones.length > 0 && (
                <Box className={ classes.root }>
                    <Box className={ clsx( {
                        [classes.rowContent]: true,
                        [classes.titleMargin]: true,
                    } ) }>
                        <Typography className={ classes.title }>
                            Связь с администратором
                        </Typography>

                        <Tooltip title="Для того чтобы связаться с администратором позвоните на один из номеров">
                            <IconButton color="primary">
                                <HelpIcon color="primary"/>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Box className={ classes.content }>
                        { adminsPhones.map( (phone, index) => (
                            <Box key={ `admin-phone-${ index }-${ phone }` }>
                                <Typography className={ classes.towLabel }>Телефон</Typography>

                                <Typography className={ classes.towValue }>
                                    <Tooltip title="Позвонить" arrow>
                                        <Link href={ `tel:+${ phone }` }>
                                            { formatPhone( phone ) }
                                        </Link>
                                    </Tooltip>
                                </Typography>
                            </Box>
                        ) ) }
                    </Box>
                </Box>
            ) }
        </>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",
    },

    titleMargin: {
        marginBottom: 32,
    },

    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)",
        },
    },

    rowContent: {
        display: "flex",
        alignItems: "center",
    },
    columnContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },

    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },
} );

export default ContactInformation;
