import React, { useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { HelpOutlineRounded as HelpIcon } from '@mui/icons-material';
import { Formik } from "formik";
import InputMask from "react-input-mask";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Notification, NotificationTypes } from "../../../../../../common/Notification";
import { convertorNumber, convertorToCbm, convertorToPiece } from "../../../../../../helper/convertor";
import { numberFormat } from "../../../../../../common/Formater";

const DialogFormConfirmFullReturn = (props) => {
    const {
        order,
        returnItems,
        isOpen,
        onClose,
        onConfirm,
    } = props;
    const refFormik = useRef( null );
    const classes = useStyles();
    const initialValues = {
        code: "",
        deliveredProductIds: order.orderItems.map( val => {
            const returnItem = returnItems.find( returnItem => returnItem.productId === val.product.id );
            const piece = convertorToPiece( returnItem.quantity, val?.product?.pieceToCbmConversionFactor || 0 );
            return {
                ...val,
                returnValue: piece,
                returnPiece: piece,
                returnCbm: returnItem.quantity,
                notReturnPiece: 0,
                notReturnCbm: 0,
            };
        } ),
    };

    const onSubmit = (form) => {
        const returnItems = form.deliveredProductIds;
        const body = {
            code: form.code,
            deliveredProductIds: returnItems.map( item => {
                return {
                    orderItemId: item.id,
                    quantity: convertorNumber( item.returnCbm, 4, '.' ),
                };
            } ),
        };

        onConfirm( body );
        handleClose();
    };

    const handleChangeReturnItemInput = (e, index, product) => {
        const { value } = e.target;
        const newForm = refFormik.current.values;
        const returnItem = returnItems.find( item => item.productId === product.product.id );
        const cbm = convertorToCbm( value, product?.product?.pieceToCbmConversionFactor || 0 );
        if (convertorNumber( cbm, 4, '.' ) <= returnItem.quantity) {
            const notReturnPiece = convertorToPiece( returnItem.quantity, product?.product?.pieceToCbmConversionFactor || 0 ) - value;
            const notReturnCbm = convertorToCbm( notReturnPiece, product?.product?.pieceToCbmConversionFactor || 0 );
            newForm.deliveredProductIds[index].returnValue = +value;
            newForm.deliveredProductIds[index].returnPiece = Number( value );
            newForm.deliveredProductIds[index].returnCbm = cbm;
            newForm.deliveredProductIds[index].notReturnPiece = notReturnPiece;
            newForm.deliveredProductIds[index].notReturnCbm = notReturnCbm;

            refFormik.current.setValues( newForm );
        } else {
            const piece = convertorToPiece( returnItem.quantity, product?.product?.pieceToCbmConversionFactor || 0 );
            newForm.deliveredProductIds[index].returnValue = piece;
            newForm.deliveredProductIds[index].returnPiece = piece;
            newForm.deliveredProductIds[index].returnCbm = returnItem.quantity;
            newForm.deliveredProductIds[index].notReturnPiece = 0;
            newForm.deliveredProductIds[index].notReturnCbm = 0;
            refFormik.current.setValues( newForm );

            Notification( {
                message: "Введенное количество больше заявленного",
                type: NotificationTypes.error,
            } );
        }
    };

    const handleChange = ({ target }) => {
        const { value, name } = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues( newForm );
    }

    const handleClose = () => {
        refFormik.current.resetForm();
        onClose();
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece( orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( orderItem.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ handleClose }
        >
            <DialogTitle>
                Подтвердить полный возврат
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ validationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box className={ classes.root }>
                                    <Box className={ classes.row }>
                                        <InputMask
                                            mask="9999"
                                            value={ values.code }
                                            maskChar=""

                                            onChange={ handleChange }
                                        >
                                            { () => <TextField
                                                error={ Boolean( touched.code && errors.code ) }
                                                helperText={ touched.code && errors.code }
                                                name="code"
                                                label="Код подтверждения"
                                                placeholder="0000"
                                                variant="outlined"
                                                fullWidth/> }
                                        </InputMask>
                                        <Tooltip title="Введите код из СМС" sx={ { cursor: "pointer" } }>
                                            <HelpIcon color="primary" fontSize="small"/>
                                        </Tooltip>
                                    </Box>
                                    <Box className={ classes.formGroup }>
                                        <Typography mb={ 1 }>Выберите возвращенные товары</Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="40%">Наименование</TableCell>
                                                    <TableCell>Кол-во</TableCell>
                                                    <TableCell>Кол-во возврата</TableCell>
                                                    <TableCell>Кол-во невозврата</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { values.deliveredProductIds.map( (val, index) => (
                                                    <TableRow key={ `item-partialReturnConfirm-${ val.id }` }>
                                                        <TableCell>{ val.productName }</TableCell>
                                                        <TableCell>{ renderQuantity( val ) }</TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={ 2 }>
                                                                <Grid item xs={ 12 }>
                                                                    <Grid container alignItems="flex-end" spacing={ 1 }>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={ {
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                } }
                                                                                value={ val.returnPiece }
                                                                                name="returnPiece"

                                                                                onChange={ (e) => handleChangeReturnItemInput( e, index, val ) }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">шт.</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={ 12 }>
                                                                    <Grid container alignItems="flex-end" spacing={ 1 }>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={ {
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                } }
                                                                                disabled
                                                                                value={ val.returnCbm }
                                                                                name="returnCbm"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">м³</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography>
                                                                { val.notReturnPiece } шт.
                                                            </Typography>
                                                            <Typography>
                                                                { val.notReturnCbm } м³
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ) ) }
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Box>
                                        <Grid container justifyContent="flex-end" spacing={ 1 }>
                                            <Grid item>
                                                <Button variant="outlined" onClick={ handleClose }
                                                        sx={ {
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px"
                                                        } }>
                                                    Отменить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={ handleSubmit }>
                                                    Подтвердить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape( {
    code: Yup.string()
        .matches( /^[0-9]{4}$/, "Код состоит 4х символов" )
        .required( 'Введите код подтверждения из СМС' ),
} )

const useStyles = makeStyles( {
    root: {
        paddingTop: 10,
    },
    row: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },

    formGroup: {
        marginTop: 16,
    },
} )

export default DialogFormConfirmFullReturn;
