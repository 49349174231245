// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import DelegatedOrder from './DelegatedOrder';

export default compose(
    connect(
        state => ( {
            settings: state.global?.settings || {},
        } ),
        dispatch => ( {} ),
    ),
)( DelegatedOrder );
