import React, { Component } from 'react';
import agent from "../../../../agent/agent";
import { Box, Typography } from "@mui/material";
import { MainInformation as MainInformationComponent, TableComponent } from './components';

class FavoriteExpeditor extends Component {
    constructor(props) {
        super( props );
        this.state = {
            expeditor: {},
            drivers: [],
            expeditorId: props?.match?.params?.id,
            isLoading: false,
        };
    }

    componentDidMount = async () => {
        await this.getExpeditor();
    };


    getExpeditor = async () => {
        this.setState( {
            isLoading: true,
        } )

        const response = await agent.get( `/favorite-expeditor/get-by-provider` )
            .then( res => res.data.expeditors )
            .catch( err => [] );

        if (response.length > 0) {
            const newExpeditor = response.find( ({ expeditor }) => +expeditor.id === +this.state.expeditorId );

            return this.setState( {
                expeditor: newExpeditor?.expeditor || {},
                drivers: newExpeditor?.drivers || [],
                isLoading: false,
            } );
        }

        this.setState( {
            isLoading: false,
        } )

    };

    render() {
        const {
            expeditor,
            drivers,
            isLoading,
        } = this.state;

        return (
            <>
                <Box mb={ 4 }>
                    <Typography variant="h1" textAlign="left">Экспедитор №{ expeditor.id }</Typography>
                </Box>

                <MainInformationComponent
                    expeditor={ expeditor }
                />

                <TableComponent
                    drivers={ drivers }
                    isLoading={ isLoading }
                />
            </>
        );
    }
}

export default FavoriteExpeditor;
