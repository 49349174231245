// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Rules from './Rules';

export default compose(
    connect(
        state => ( {} ),
        dispatch => ( {} ),
    ),
)( Rules );
