import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";

const ReceivedOrderInformation = (props) => {
    const {
        actions
    } = props;
    const classes = useStyles();

    return (
        <Box className={ classes.root }>

            <Typography className={ classes.title }>
                Отгрузка
            </Typography>

            <Box className={ classes.content }>
                { actions.map( (action, index) => (
                    <Button key={ `ready-button-${ index }` } className={ classes.actionButton }
                            onClick={ action.onClick }>
                        { action.label }
                    </Button>
                ) ) }
            </Box>

        </Box>
    );
};

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 16
    },
    content: {
        display: "flex",
        flexWrap: "wrap",

        marginLeft: -24,

        "& > *": {
            marginLeft: 24,
        }
    },

    actionButton: {
        height: 45,
        borderRadius: 100,
        backgroundColor: "white",
        border: "1px solid #84B92C",
        boxShadow: "0px 7px 14px rgba(0, 0, 0, 0.07)",
        padding: "0 24px",

        color: "#84B92C",
        fontWeight: "600",

        "&:hover": {
            backgroundColor: "#84B92C",
            color: "white"
        }
    }
} ) );

export default ReceivedOrderInformation;
