import React, { useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from 'yup';
import { makeStyles } from "@mui/styles";

const DialogFormSetFavoriteExpeditor = (props) => {
    const {
        expeditors,
        isOpen,
        onClose,
        onSetExpeditor,
    } = props;

    const refFormik = useRef( null );
    const classes = useStyles();
    const initValue = {
        expeditorId: "",
    };

    const onSubmit = (form) => {
        onSetExpeditor( form.expeditorId );
        handleClose();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues( newForm );
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ handleClose }
        >
            <DialogTitle>
                Назначить своего экспедитора
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initValue }
                    validationSchema={ validationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box mt={ 2 }>
                                    <FormControl fullWidth
                                                 error={ Boolean( touched.expeditorId && errors.expeditorId ) }>
                                        <InputLabel
                                            id="select-favorite-expeditor-label">Экспедитор</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="select-favorite-expeditor-label"
                                            id="select-favorite-expeditor"
                                            value={ values.expeditorId }
                                            name="expeditorId"
                                            label="Экспедитор"
                                            onChange={ handleChange }
                                        >
                                            { expeditors.map( expeditor => (
                                                <MenuItem
                                                    value={ expeditor.id }
                                                    disabled={ !expeditor.active }
                                                >
                                                    { expeditor.id } | { expeditor.lastName } { expeditor.firstName } { !expeditor.active && "(деактивирован)" }
                                                </MenuItem>
                                            ) ) }
                                        </Select>
                                        { Boolean( touched.expeditorId && errors.expeditorId ) && (
                                            <FormHelperText>{ touched.expeditorId && errors.expeditorId }</FormHelperText>
                                        ) }
                                    </FormControl>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            onClick={ handleClose }
                                        >
                                            Отменить
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={ handleSubmit }
                                        >
                                            Назначить
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape( {
    expeditorId: Yup.number().required( 'Выберите экспедитора' ),
} );

const useStyles = makeStyles( {
    deactivatedExpeditor: {
        textDecoration: "line-through",
        pointerEvents: "none",
    },
} );

export default DialogFormSetFavoriteExpeditor;
