import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { HelpOutlineRounded as HelpIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

const initialValues = {
    code: '',
}

const DialogPickupReturnConfirm = (props) => {
    const {
        isOpen,
        onClose,
        onConfirm,
    } = props;

    const classes = useStyles();
    const refFormik = React.useRef( null );

    const onSubmit = (form) => {
        onConfirm( form );
        handleCloseModal();
    };


    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues( newForm );
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                <Typography variant="h3">
                    Подтверждение возврата
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ validationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={ 1 }>
                                    <Box className={ classes.rowContent }>
                                        <InputMask
                                            mask="999999"
                                            value={ values.code }
                                            maskChar=""
                                            onChange={ handleChange }
                                        >
                                            { () =>
                                                <TextField
                                                    value={ values.code }
                                                    error={ Boolean( touched.code && errors.code ) }
                                                    helperText={ touched.code && errors.code }
                                                    name="code"
                                                    label="Код подтверждения"
                                                    placeholder="000000"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            }
                                        </InputMask>
                                        <Tooltip title="Введите код который Вам сообщат">
                                            <HelpIcon
                                                fontSize="small"
                                                color={ Boolean( touched.code && errors.code ) ? "error" : "primary" }
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={ {
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        } }

                                        onClick={ handleCloseModal }
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={ {
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        } }

                                        onClick={ handleSubmit }
                                    >
                                        Подтвердить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape( {
    code: Yup.string()
        .matches( /^[0-9]{6}$/, "Код состоит из 6-ти символов" )
        .required( 'Введите код подтверждения который сообщат Вам' ),
} );

const useStyles = makeStyles( {
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
} )

export default DialogPickupReturnConfirm;
