import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import { IMaskInput } from "react-imask";
import { Notification, NotificationTypes } from "../../../../../../common/Notification";
import * as Yup from "yup";
import { convertorNumber } from "../../../../../../helper/convertor";

const DialogFormSetSale = (props) => {
    const {
        orderPrice,
        isOpen,
        onClose,
        onSetOrderSale,
    } = props;

    const refFormik = React.useRef( null );
    const [ initialValues, setInitialValues ] = React.useState( {
        discountValue: "",
    } );

    React.useEffect( () => {
        if (isOpen) {
            setInitialValues( {
                discountValue: "",
            } );
        }
    }, [ isOpen ] );

    const onSubmit = (form) => {
        onSetOrderSale( form );
        handleClose();
    };

    const handleChange = ({ target }) => {
        const { value, name } = target;
        const newForm = refFormik.current.values;

        if (+value < +orderPrice) {
            newForm[name] = value + '';
        } else {
            newForm[name] = "";

            const message = `Сумма скидки не должна превышать стоимость заказа. Стоимость заказа: ${ convertorNumber( orderPrice, 2, '.' ) } ₽`;

            Notification( {
                message,
                type: NotificationTypes.error,
            } );
        }

        refFormik.current.setValues( newForm );
    };

    const handleClose = () => {
        refFormik.current.resetForm();
        onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }

            onClose={ handleClose }
        >

            <DialogTitle>
                <Typography variant="h3">Скидка</Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ validateScheme }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box py={ 2 }>
                                    <TextField
                                        value={ values.discountValue }
                                        fullWidth
                                        name="discountValue"
                                        label="Скидка"
                                        InputProps={ {
                                            inputComponent: CustomInputAmount
                                        } }
                                        inputProps={ {
                                            scale: 2,
                                        } }
                                        error={ touched.discountValue && Boolean( errors.discountValue ) }
                                        helperText={ touched.discountValue && errors.discountValue }
                                        placeholder="Введите скидку"

                                        onChange={ handleChange }
                                    />
                                </Box>
                                <DialogActions>
                                    <Box>
                                        <Grid container justifyContent="flex-end" spacing={ 1 }>
                                            <Grid item>
                                                <Button variant="outlined" onClick={ handleClose }
                                                        sx={ {
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px"
                                                        } }>
                                                    Отменить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={ handleSubmit }>
                                                    Применить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const CustomInputAmount = ({ inputRef, ...rest }) => (
    <IMaskInput
        ref={ inputRef }
        { ...rest }

        mask={ Number }
        thousandsSeparator=""
        radix="."
        mapToRadix={ [ ',' ] }
        scale={ rest?.scale || 0 }
        unmask={ true }
    />
)

const validateScheme = Yup.object().shape( {
    discountValue: Yup.string().required( 'Введите скидку' ),
} )

export default DialogFormSetSale;
