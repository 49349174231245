import React from 'react';
import { IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { DeleteRounded as DeleteIcon } from '@mui/icons-material';
import { formatPhone } from "../../../../../../helper/formatPhone";

const TableComponent = (props) => {
    const {
        expeditors,
        isLoading,
        onDeleteExpeditor,
        onRouteExpeditor
    } = props;

    const handleClickRow = (expeditorId, event) => {
        const isDisableRoute = Boolean( event.target.closest( ".disable-route" ) );

        if (isDisableRoute) {
            return null;
        }

        onRouteExpeditor( expeditorId );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>№ экспедитора</TableCell>
                    <TableCell>Фамилия</TableCell>
                    <TableCell>Имя</TableCell>
                    <TableCell>Телефон</TableCell>
                    <TableCell width="40px"/>
                </TableRow>
            </TableHead>
            <TableBody>
                { isLoading
                    ? <>
                        { [ 1, 2, 3, 4, 5 ].map( (item, i) => (
                            <TableRow key={ `skeleton-table-${ i }` }>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                                <TableCell><Skeleton/></TableCell>
                            </TableRow>
                        ) ) }
                    </>
                    : <>
                        { expeditors.map( ({ expeditor }) => (
                            <TableRow
                                key={ `favorite-expeditor-id-${ expeditor.id }` }

                                onClick={ (e) => handleClickRow( expeditor.id, e ) }
                            >
                                <TableCell>{ expeditor.id }</TableCell>
                                <TableCell>{ expeditor.lastName }</TableCell>
                                <TableCell>{ expeditor.firstName }</TableCell>
                                <TableCell>{ formatPhone( expeditor.phone ) }</TableCell>
                                <TableCell>
                                    <IconButton
                                        color='error'
                                        className="disable-route"
                                        onClick={ onDeleteExpeditor.bind( this, expeditor.id, false ) }
                                    >
                                        <DeleteIcon color='error'/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ) ) }
                    </>
                }
            </TableBody>
        </Table>
    );
};

export default TableComponent;
