import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

class DialogConfirmation extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            open: false,

            title: "",
            message: "",
            acceptButtonTitle: "",
            cancelButtonTitle: "",
            acceptButtonAction: "",
            cancelButtonAction: "",
        }
    }

    onOpen = (props) => {
        this.setState( {
            open: true,

            title: props.title || '',
            message: props.message || '',
            acceptButtonTitle: props.acceptButtonTitle || '',
            cancelButtonTitle: props.cancelButtonTitle || '',
            acceptButtonAction: props.acceptButtonAction || '',
            cancelButtonAction: props.cancelButtonAction || '',
        } )
    }

    onAccept = () => {
        this.setState( {
            open: false
        } );

        if (!this.state.acceptButtonAction) {
            return null
        }

        this.state.acceptButtonAction();
    }
    onCancel = () => {
        this.setState( {
            open: false
        } );

        if (!this.state.cancelButtonAction) {
            return null
        }

        this.state.cancelButtonAction();
    }

    render() {
        const {
            open,

            title,
            message,
            acceptButtonTitle,
            cancelButtonTitle
        } = this.state;

        return (
            <Dialog open={ open } fullWidth maxWidth="md" onClose={ this.onCancel }>

                <DialogTitle sx={ { backgroundColor: "#84b92c" } }>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h3" sx={ { color: "white" } }>
                                { title || "Подтверждение" }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={ this.onCancel }>
                                <CloseIcon sx={ { color: "white" } }/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Box py={ 3 }>
                        <Typography variant="subtitle1">
                            { message || "Вы действительно хотите это сделать?" }
                        </Typography>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Grid container spacing={ 2 } justifyContent="flex-end">
                        <Grid item>
                            <Button variant="outlined" fullWidth onClick={ this.onCancel }
                                    sx={ { borderRadius: "4px", textTransform: "initial" } }>
                                Отменить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" fullWidth onClick={ this.onAccept }
                                    sx={ { borderRadius: "4px", textTransform: "initial" } }>
                                { acceptButtonTitle || "Применить" }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DialogConfirmation
