import React, { useState } from "react";
import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Map from "../../../../../../components/Map";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";


const MainOrderInformation = (props) => {
    const {
        order,
        settings,
        onChangeDeliveryDate
    } = props;
    const classes = useStyles();
    const [ isShowMap, setIsShowMap ] = useState( false );

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }
    const _deliveryDate = () => {
        const date = moment( order?.deliveryDate ).format( "DD.MM.YYYY" );
        const timesDelivery = deliveryTime( settings );
        const timespan = timesDelivery.filter( time => time.value === order?.deliveryTimespan );

        return `${ date } (${ timespan[0]?.label || "Время не указано" })`;
    }
    const _deliveryAddress = () => {
        return order?.deliveryAddress || "Милицейский адрес не найден";
    }

    return (
        <Box className={ classes.root }>

            <Typography className={ classes.title }>
                Основная информация о заказе
            </Typography>

            <Box className={ classes.content }>

                <Box className={ classes.row }>
                    <Typography className={ classes.towLabel }>Срок выполнения</Typography>
                    <Typography className={ classes.towValue }>
                        { _deliveryDate() }
                    </Typography>
                </Box>

                <Box className={ classes.row }>
                    <Typography className={ classes.towLabel }>Способ доставки</Typography>
                    <Typography className={ classes.towValue }>
                        { _getDeliveryMethod( order.deliveryMethod ) }
                    </Typography>
                </Box>

                { order.deliveryMethod === "delivery" && (
                    <Box className={ classes.row }>
                        <Typography className={ classes.towLabel }>Адрес доставки</Typography>
                        <Grid container alignItems="center" spacing={ 2 }>
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { _deliveryAddress() }
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={ classes.buttonChangeDeliveryDate }
                                    color="primary"
                                    variant="contained"

                                    onClick={ () => setIsShowMap( !isShowMap ) }
                                >
                                    Развернуть карту
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                ) }

                {/*<Box className={classes.row}>*/ }
                {/*    <Typography className={classes.towLabel}>Стоимость доставки</Typography>*/ }
                {/*    <Typography className={classes.towValue}>*/ }
                {/*        {convertorNumber(order.finalDeliveryPrice, 2)} р.*/ }
                {/*    </Typography>*/ }
                {/*</Box>*/ }

                {/*<Box className={classes.row}>*/ }
                {/*    <Typography className={classes.towLabel}>Общая стоимость</Typography>*/ }
                {/*    <Typography className={classes.towValue}>*/ }
                {/*        {convertorNumber(order.totalPrice, 2)} р.*/ }
                {/*    </Typography>*/ }
                {/*</Box>*/ }

            </Box>

            { order.deliveryMethod === "delivery" && (
                <Collapse in={ isShowMap } timeout="auto" sx={ { marginTop: "20px" } }>
                    <Box className={ classes.content }>
                        <Box className={ classes.row } mb={ 1 }>
                            <Typography className={ classes.towLabel }>Широта</Typography>
                            <Typography className={ classes.towValue }>
                                { order?.deliveryAddressLat || "-" }
                            </Typography>
                        </Box>

                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Долгота</Typography>
                            <Typography className={ classes.towValue }>
                                { order?.deliveryAddressLong || "-" }
                            </Typography>
                        </Box>
                    </Box>
                    <Box height={ 420 }>
                        <Map
                            center={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                            coords={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                        />
                    </Box>
                </Collapse>
            ) }

        </Box>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 32
    },
    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)"
        }
    },

    row: {},
    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white",
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    }
} ) );

export default MainOrderInformation
