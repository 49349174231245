import React from "react";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { convertorNumber, convertorToPiece } from "../../../../../../helper/convertor";
import { providerPrice } from "../../../../../../helper/providerPrice";
import { numberFormat } from "../../../../../../common/Formater";

const ProductsOrderInformation = (props) => {
    const {
        order,
        products,
        systemPercent,
        returnItems,
        shortageItems
    } = props;
    const classes = useStyles();

    // Показывать ли кнопку "Заказать круглять"
    const _isShowOrderRoundTimber = () => {
        return true
    }

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece( product.quantity, product?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( product.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const renderProductPrices = (product) => {
        return (
            <>
                <Typography>{ numberFormat( providerPrice( product.product.piecePrice, systemPercent ), 2 ) } ₽/шт.</Typography>
                <Typography>{ numberFormat( providerPrice( product.product.cbmPrice, systemPercent ), 2 ) } ₽/м³</Typography>
            </>
        )
    };

    const getOrderSum = () => {
        if ([ 'partialReturn' ].includes( order.deliveryStatus ) && ![ 'created' ].includes( order.statusOfReturnOrder )) {
            const returnItemsSum = returnItems.reduce( (acc, curr) => +acc + +curr.price, 0 );
            const newSum = +order.sum - +returnItemsSum;

            return `${ convertorNumber( providerPrice( newSum, systemPercent ), 2 ) } ₽`;
        }
        if ([ 'fullReturn' ].includes( order.deliveryStatus ) && ![ 'created' ].includes( order.statusOfReturnOrder )) {
            return `${ convertorNumber( 0, 2 ) } ₽`;
        }

        return `${ convertorNumber( providerPrice( order.sum, systemPercent ), 2 ) } ₽`;
    };

    const renderShortageItemsPrice = () => {
        const totalPrice = shortageItems.reduce( (acc, curr) => +acc + +curr.sum, 0 );

        return (
            <Typography fontWeight="600" fontSize={ 18 }>
                { convertorNumber( providerPrice( totalPrice, systemPercent ), 2 ) } ₽
            </Typography>
        );
    };

    return (
        <Box className={ classes.root }>

            <Box className={ classes.head }>
                <Grid container spacing={ 2 } alignItems="center">
                    <Grid item>
                        <Typography className={ classes.title }>
                            Состав заказа
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Table className={ classes.table }>

                <TableHead>
                    <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell align="right">Кол-во</TableCell>
                        <TableCell align="right">Цена</TableCell>
                        <TableCell align="right">Стоимость</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    { ( products || [] ).map( (product, index) => (
                        <TableRow>
                            <TableCell>{ product.productName }</TableCell>
                            <TableCell
                                align="right">{ renderProductQuantity( product ) }</TableCell>
                            <TableCell align="right">{ renderProductPrices( product ) }</TableCell>
                            <TableCell
                                align="right">{ convertorNumber( providerPrice( product.price, systemPercent ), 2 ) } р.</TableCell>
                        </TableRow>
                    ) ) }
                </TableBody>
            </Table>

            { returnItems.length > 0 && (
                <>
                    <Table className={ classes.tableInfoPrice }>
                        <TableBody>
                            <TableRow>
                                <TableCell width="70%"/>
                                <TableCell>
                                    <Typography fontWeight="700" fontSize={ 21 }>Стоимость заказа:</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="700" fontSize={ 21 }>
                                        { convertorNumber( providerPrice( order.sum, systemPercent ), 2 ) } р.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Typography className={ classes.title } pl={ 1 } mt={ 2 }>Возврат</Typography>

                    <Table className={ classes.table }>

                        <TableHead>
                            <TableRow>
                                <TableCell>Наименование</TableCell>
                                <TableCell align="right">Кол-во возврата</TableCell>
                                <TableCell align="right">Цена</TableCell>
                                <TableCell align="right">Стоимость</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { ( returnItems || [] ).map( (product, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>{ product.product.name }</TableCell>
                                        <TableCell
                                            align="right">{ renderProductQuantity( product ) }</TableCell>
                                        <TableCell align="right">{ renderProductPrices( product ) }</TableCell>
                                        <TableCell
                                            align="right">{ convertorNumber( providerPrice( product.price, systemPercent ), 2 ) } р.</TableCell>
                                    </TableRow>
                                )
                            } ) }
                        </TableBody>
                    </Table>
                </>
            ) }

            <Table className={ classes.tableInfoPrice }>
                <TableBody>
                    { order.discount > 0 && (
                        <TableRow>
                            <TableCell width={ returnItems.length > 0 ? "50%" : "70%" }/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={ 18 }>
                                    Скидка исполнителя:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={ 18 }>
                                    { convertorNumber( order?.discount, 2 ) } ₽
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) }
                    { order.adminDiscount > 0 && (
                        <TableRow>
                            <TableCell width={ returnItems.length > 0 ? "50%" : "70%" }/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={ 18 }>
                                    Скидка администратора:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={ 18 }>
                                    { convertorNumber( order?.adminDiscount, 2 ) } ₽
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ) }
                    <TableRow>
                        <TableCell width={ returnItems.length > 0 ? "60%" : "70%" }/>
                        <TableCell>
                            <Typography fontWeight="700" fontSize={ 21 }>
                                Стоимость заказа{ returnItems.length > 0 && " после возврата" }:
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="700" fontSize={ 21 }>
                                { getOrderSum() }
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            { shortageItems.length > 0 && (
                <>

                    <Typography className={ classes.title } pl={ 1 } mt={ 2 }>Недовоз</Typography>

                    <Table className={ classes.table }>

                        <TableHead>
                            <TableRow>
                                <TableCell>Наименование</TableCell>
                                <TableCell align="right">Кол-во недовоза</TableCell>
                                <TableCell align="right">Цена</TableCell>
                                <TableCell align="right">Стоимость</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { ( shortageItems || [] ).map( (product, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>{ product.product.name }</TableCell>
                                        <TableCell
                                            align="right">{ renderProductQuantity( product ) }</TableCell>
                                        <TableCell align="right">{ renderProductPrices( product ) }</TableCell>
                                        <TableCell
                                            align="right">{ convertorNumber( providerPrice( product.sum, systemPercent ), 2 ) } р.</TableCell>
                                    </TableRow>
                                )
                            } ) }
                        </TableBody>
                    </Table>

                    <Table className={ classes.tableInfoPrice }>
                        <TableBody>
                            <TableRow>
                                <TableCell width="65%"/>
                                <TableCell>
                                    <Typography fontWeight="700" fontSize={ 21 }>
                                        Стоимость недовоза:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="700" fontSize={ 21 }>
                                        { renderShortageItemsPrice() }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            ) }

        </Box>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500"
    },
    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    tableInfoPrice: {
        "& tr td": {
            fontSize: 14,
            color: "black",
            fontWeight: "400",
            padding: "10px",
            backgroundColor: "white",
        },
    },

    buttonSmall: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white",

    }
} ) );
export default ProductsOrderInformation
