import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";

const DialogChoiceDeliveryActions = (props) => {
    const {
        isOpen,
        onClose,
        onSetFavoriteExpeditor,
        onRouteToTheExpeditorsList
    } = props;
    const classes = useStyles();

    const onSet = () => {
        onSetFavoriteExpeditor( true );
        onClose();
    };

    const onRoute = () => {
        onRouteToTheExpeditorsList();
        onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ onClose }
        >
            <DialogTitle>
                Дальнейшие действия
            </DialogTitle>
            <DialogContent>
                <Box className={ classes.rowContent }>
                    <Button
                        fullWidth
                        size="large"
                        variant='contained'

                        onClick={ onSet }
                    >
                        Установить своего экспедитора
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"

                        onClick={ onRoute }
                    >
                        Отправить в список экспедиторов
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles( {
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    }
} )

export default DialogChoiceDeliveryActions;
