import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteWithLayout } from "../components";
import { Minimal as MinimalLayout } from "../layouts";
import { Authorization as AuthorizationPage } from "../pages";
import { delegatedOrderPage } from "./DelegatedOrderRouter";

const pages = [
    {
        path: '/',
        component: AuthorizationPage,
        layout: MinimalLayout,
        rules: [],
        exact: true
    },
    delegatedOrderPage
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map( (page, idx) => (
                    <RouteWithLayout
                        key={ 'page-' + idx }
                        { ...page }
                    />
                ) )
            }

            <Redirect to="/"/>
        </Switch>
    );
};

export default MainRoutes;
