import React, { Component } from 'react';
import queryString from "query-string";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import { withStyles } from "@mui/styles";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DialogFormChangeCode, MainOrderInformation, OrderActions } from "./components";
import { DialogConfirmation } from "../../../../components";
import {
    ContactInformation,
    DialogFormTransferTheGoods,
    DialogReturnConfirm, LifeCycle as LifeCycleComponent,
    OrderPhotos as OrderPhotosComponent,
    ProductsOrderInformation as ProductsOrderInformationComponent,
    DialogFormReadyToOrder as DialogFormReadyToOrderComponent,
} from "../../my-orders/MyOrder/components";

class DelegatedOrder extends Component {
    constructor(props) {
        super( props );

        this.state = {
            order: {},
            hash: null,
            orderId: null,
            delegatedId: null,

            adminsPhones: [],

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],
            billOfLadingPhotos: [],

            isLoading: true,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingBillOfLadingPhotos: false,

            isOpenTransferTheGoods: false,
            isOpenReturnConfirm: false,
            isOpenChangeCode: false,
            isOpenReadyToOrder: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initParams();

        if (this.state.hash === null) {
            this.props.history.push( `/` );

            Notification( {
                message: "Неверная ссылка, сгенерируйте снова",
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getOrder();
    }

    initParams = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse( locationSearch, {
            arrayFormat: "bracket"
        } );

        await this.setState( {
            hash: parseSearch?.hash || null,
            orderId: parseSearch?.orderId || null,
            delegatedId: parseSearch?.delegatedId || null,
        } );
    };

    getOrder = async () => {
        if (this.state.hash === null || this.state.orderId === null || this.state.delegatedId === null) {
            Notification( {
                message: "Неверная ссылка, сгенерируйте снова",
                type: NotificationTypes.error,
            } );

            this.props.history.push( '/' );

            return;
        }

        this.setState( { isLoading: true } );

        const response = await agent.get( `/delegated-orders/${ this.state.delegatedId }/get-order?hash=${ this.state.hash }` )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                    status: err.response.status,
                };
            } );

        if (response.error) {
            let message = response.error?.data?.message || "Нет доступа к заказу";

            if (response.status === 400) {
                message = 'Ссылка недействительна, сгенерируйте новую ссылку и попробуйте снова';
            }

            Notification( {
                message: message,
                type: NotificationTypes.error,
            } );

            this.props.history.push( '/' );

            return;
        }

        this.setState( {
            isLoading: false,
            order: response.order,
            adminsPhones: response.adminsPhones,
        } );
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos( type, true );

        const response = await agent.get( `/delegated-orders/${ this.state.orderId }/get-photos-without-authorization?neededType=${ type }`, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( res => res.data.photos )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setLoadingStatusOrderPhotos( type, false );

            Notification( {
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            } );

            return null;
        }

        let newPhotos = [];

        if (Array.isArray( response ) && response.length > 0) {
            newPhotos = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.photo.data ).buffer ] ) ) ) ];
        }

        this.setState( { [type]: newPhotos } );

        this.setLoadingStatusOrderPhotos( type, false );

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification( {
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            } );
        }
    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState( { isLoadingProviderPhotos: status } );
        type === 'driverPhotos' && this.setState( { isLoadingDriverPhotos: status } );
        type === 'returnPhotos' && this.setState( { isLoadingReturnPhotos: status } );
        type === 'billOfLadingPhotos' && this.setState( { isLoadingBillOfLadingPhotos: status } );
    };

    transferTheGoods = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.transferTheGoods.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const body = {
            orderPhotosInBase64: form.photo
        };

        const response = await agent.put( `/delegated-orders/${ this.state.orderId }/execute-action`, body, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            let message = response.error?.data?.message || "Ошибка при передаче груза";

            Notification( {
                message,
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getOrder();
        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Груз успешно передан, ожидайте подтверждения экспедитора/водителя",
            type: NotificationTypes.success,
        } );
    };

    returnConfirm = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите подтвердить возврат?",
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.returnConfirm.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/delegated-orders/${ this.state.orderId }/execute-action`, form, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            let message = response.error?.data?.message || "Ошибка при принятии возврата";

            Notification( {
                message,
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );

        await this.getOrder();

        Notification( {
            message: "Возврат успешно подтвержден, сообщите код экспедитору или водителю",
            type: NotificationTypes.success,
        } );
    };

    executeAction = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите провести действие?",
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.executeAction.bind( this, code, true ),
            } );

            return;
        }

        this.setState( { isShowBackdrop: true } );

        let form = {};

        if (code && code.length > 0) {
            form.code = code
        }

        const response = await agent.put( `/delegated-orders/${ this.state.orderId }/execute-action`, form, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            let message = response.error?.data?.message || "Ошибка при принятии возврата";

            Notification( {
                message,
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );

        await this.getOrder();

        Notification( {
            message: "Действие успешно проведено",
            type: NotificationTypes.success,
        } );
    };

    // Логика кнопки "Заказ принят"
    readyToOrder = async (form = {}, isConfirm) => {
        if (Object.keys( form ).length <= 0) {
            this.setIsOpenReadyToOrder( true );

            return;
        }

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно готовы передать заказ заказчику?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.readyToOrder.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        this.handleCloseReadyToOrder();

        const response = await agent.put( `/delegated-orders/${ this.state.orderId }/execute-action`, {
            orderPhotosInBase64: form?.photo || [],
        }, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );
            Notification( {
                message: response.error?.data?.message || "Ошибка при подготовке передачи заказа",
                type: NotificationTypes.error,
            } )

            return null;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Передайте груз заказчику с помощью кода, который Вам сообщит заказчик",
            type: NotificationTypes.success,
        } );
    };

    // Получение кнопок управление "Производство"
    _actionsButtons = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || "";
        const deliveryMethod = this.state.order?.deliveryMethod || "";
        const returnStatus = this.state.order?.statusOfReturnOrder || "";
        let buttons = [];

        // Показывать кнопку "Подтвердить возврат"
        const isReturnConfirm = [ 'pickup' ].includes( deliveryMethod ) && [ 'purchaserConfirmed' ].includes( returnStatus );
        if (isReturnConfirm) {
            buttons.push( {
                label: "Подтвердить возврат",
                onClick: this.setIsOpenChangeCode.bind( this, true ),
                disable: false,
            } );
        }

        // Показывать кнопку "Готов передать заказ"
        const isReadyForShipment = [ 'pickup' ].includes( deliveryMethod ) && [ 'readyForShipment' ].includes( deliveryStatus );
        if (isReadyForShipment) {
            buttons.push( {
                label: "Готов передать заказ",
                onClick: this.readyToOrder.bind( this, {}, false ),
                disable: false,
            } );
        }


        // Показывать кнопку "Передать заказ клиенту"
        const isTransferringToClient = [ 'pickup' ].includes( deliveryMethod ) && [ 'transferringToClient' ].includes( deliveryStatus );
        if (isTransferringToClient) {
            buttons.push( {
                label: "Передать заказ клиенту",
                onClick: this.setIsOpenChangeCode.bind( this, true ),
                disable: false,
            } );
        }

        // Показывать кнопку "Передать груз" - проверил (работает)
        const isLoaded = [ 'delivery' ].includes( deliveryMethod ) && [ 'loaded' ].includes( deliveryStatus );
        if (isLoaded) {
            buttons.push( {
                label: "Передать груз",
                onClick: this.setIsOpenTransferTheGoods.bind( this, true ),
                disable: false,
            } );
        }

        // Показывать кнопку "Принять возврат" - проверил (работает)
        const isTransferredToProvider = [ 'delivery' ].includes( deliveryMethod ) && [ 'fullReturn', 'partialReturn' ].includes( deliveryStatus )
            && [ 'transferredToProvider' ].includes( returnStatus );
        if (isTransferredToProvider) {
            buttons.push( {
                label: "Принять возврат",
                onClick: this.setIsOpenReturnConfirm.bind( this, true ),
                disable: false,
            } );
        }

        return buttons;
    };

    // Получение статусов по заказу
    _statusItems = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || ""
        const deliveryMethod = this.state.order?.deliveryMethod || "";

        if (deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        let orderStatuses = [
            {
                label: "Принят в работу",
                active: true,
                error: false,
            },
            {
                label: "Частично готов",
                active: [ "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Готов",
                active: [ "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Ожидает погрузки",
                active: [ 'courierInstalled', 'loaded', "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Загружен",
                active: [ "enRoute", "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Доставлен",
                active: [ "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ? "Не выполнен" : "Выполнен" ),
                active: [ "received" ].includes( deliveryStatus ) || ( [ 'partialReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
                error: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
            }
        ];

        return orderStatuses;
    }

    setIsOpenTransferTheGoods = (isOpen) => {
        this.setState( {
            isOpenTransferTheGoods: isOpen,
        } );
    };

    handleCloseTransferTheGoods = () => {
        this.setIsOpenTransferTheGoods( false );
    };

    setIsOpenReturnConfirm = (isOpen) => {
        this.setState( { isOpenReturnConfirm: isOpen } );
    };

    handleCloseReturnConfirm = () => {
        this.setIsOpenReturnConfirm( false );
    };

    setIsOpenChangeCode = (isOpen) => {
        this.setState( { isOpenChangeCode: isOpen } );
    };

    handleCloseChangeCode = () => {
        this.setIsOpenChangeCode( false );
    };

    // Открытие формы подготовки к передаче заказа
    setIsOpenReadyToOrder = (isOpen) => {
        this.setState( {
            isOpenReadyToOrder: isOpen,
        } );
    };
    // Закрытие формы подготовки к передаче заказа
    handleCloseReadyToOrder = () => {
        this.setIsOpenReadyToOrder( false );
    };

    render() {
        const {
            order,
            orderId,
            delegatedId,

            adminsPhones,

            providerPhotos,
            driverPhotos,
            returnPhotos,
            billOfLadingPhotos,

            isLoading,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingBillOfLadingPhotos,

            isOpenTransferTheGoods,
            isOpenReturnConfirm,
            isOpenChangeCode,
            isOpenReadyToOrder,

            isShowBackdrop
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <>
                { isLoading
                    ? <Box>Загрузка...</Box>
                    : <Box>
                        <Box mb={ 2 }>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container alignItems="center" spacing={ 2 }>
                                        <Grid item>
                                            <Typography variant="h1">
                                                Заказ №{ orderId }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <LifeCycleComponent
                                                items={ this._statusItems() }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <MainOrderInformation
                            order={ order }
                        />

                        <ContactInformation
                            order={ order }
                            adminsPhones={ adminsPhones }
                        />

                        <ProductsOrderInformationComponent
                            order={ order }
                            products={ ( order?.orderItems || [] ) }
                            systemPercent={ this.props.settings.systemPercent }
                            returnItems={ order?.orderReturn?.returnItems || [] }
                            shortageItems={ order?.orderReturn?.shortageItems || [] }
                        />

                        { ( order.attachedPhotosFlags &&
                            (
                                order.attachedPhotosFlags.billOfLadingPhotos ||
                                order.attachedPhotosFlags.fromDriver ||
                                order.attachedPhotosFlags.fromProvider
                            )
                        ) && (
                            <OrderPhotosComponent
                                showBillOfLadingPhotos={ false }

                                order={ order }
                                orderDeliveryStatus={ order?.deliveryStatus || "" }
                                attachedPhotoFlags={ order.attachedPhotosFlags }

                                providerPhotos={ providerPhotos }
                                driverPhotos={ driverPhotos }
                                returnPhotos={ returnPhotos }
                                billOfLadingPhotos={ billOfLadingPhotos }

                                isLoadingProviderPhotos={ isLoadingProviderPhotos }
                                isLoadingDriverPhotos={ isLoadingDriverPhotos }
                                isLoadingReturnPhotos={ isLoadingReturnPhotos }
                                isLoadingBillOfLadingPhotos={ isLoadingBillOfLadingPhotos }

                                getPhotos={ this.getOrderPhotosUsingType }
                            />
                        ) }

                        { this._actionsButtons().length > 0 && (
                            <OrderActions
                                buttons={ this._actionsButtons() }
                            />
                        ) }


                        <DialogFormTransferTheGoods
                            order={ order }
                            isOpen={ isOpenTransferTheGoods }
                            onClose={ this.handleCloseTransferTheGoods }
                            onTransfer={ this.transferTheGoods }
                        />


                        { ( [ 'fullReturn', 'partialReturn' ].includes( order.deliveryStatus ) && [ 'transferredToProvider' ].includes( order.statusOfReturnOrder ) ) && (
                            <DialogReturnConfirm
                                isOpen={ isOpenReturnConfirm }
                                order={ order }
                                returnItems={ order?.orderReturn?.returnItems || [] }
                                onClose={ this.handleCloseReturnConfirm }
                                onConfirm={ this.returnConfirm }
                            />
                        ) }

                        <DialogFormChangeCode
                            isOpen={ isOpenChangeCode }
                            onClose={ this.handleCloseChangeCode }
                            onAccept={ this.executeAction }
                        />

                        <DialogFormReadyToOrderComponent
                            isOpen={ isOpenReadyToOrder }
                            onClose={ this.handleCloseReadyToOrder }
                            onReady={ this.readyToOrder }
                        />
                    </Box>
                }

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

const styles = {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },
};

export default withStyles( styles )( DelegatedOrder );
