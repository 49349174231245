import React from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { convertorNumber, convertorToPiece } from "../../../../../../helper/convertor";
import { providerPrice } from "../../../../../../helper/providerPrice";
import { numberFormat } from "../../../../../../common/Formater";

const ProductsOrderInformation = (props) => {
    const {
        products,
        order,
        systemPercent,
    } = props;
    const classes = useStyles();

    const getTotalPrice = () => {
        return providerPrice( order.sum, +systemPercent ) + +order.finalDeliveryPrice;
    }

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece( product.quantity, product?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( product.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const renderProductPrices = (product) => {
        return (
            <>
                <Typography>{ providerPrice( product.product.piecePrice, systemPercent ) } ₽/шт.</Typography>
                <Typography>{ providerPrice( product.product.cbmPrice, systemPercent ) } ₽/м³</Typography>
            </>
        )
    };

    return (
        <Box className={ classes.root }>

            <Box className={ classes.head }>
                <Typography className={ classes.title }>
                    Состав заказа
                </Typography>
            </Box>

            <Table className={ classes.table }>

                <TableHead>
                    <TableRow>
                        <TableCell width="25%">Наименование</TableCell>
                        <TableCell>Кол-во</TableCell>
                        <TableCell align="right">Цена</TableCell>
                        <TableCell align="right">Стоимость</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    { ( products || [] ).map( (product, index) => (
                        <TableRow>
                            <TableCell>{ product.productName }</TableCell>
                            <TableCell>{ renderProductQuantity( product ) }</TableCell>
                            <TableCell align="right">
                                { renderProductPrices( product ) }
                            </TableCell>
                            <TableCell align="right">
                                { convertorNumber( providerPrice( product.price, +systemPercent ), 2 ) } ₽
                            </TableCell>
                        </TableRow>
                    ) ) }
                </TableBody>

            </Table>

            <Table className={ classes.tableInfoPrice }>
                <TableBody>
                    <TableRow>
                        <TableCell width="70%"/>
                        <TableCell>
                            <Typography fontWeight="700" fontSize={ 21 }>Стоимость заказа:</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography fontWeight="700"
                                        fontSize={ 21 }>{ convertorNumber( providerPrice( order.sum, +systemPercent ), 2 ) } ₽</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

        </Box>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500"
    },
    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    tableInfoPrice: {
        "& tr td": {
            fontSize: 14,
            color: "black",
            fontWeight: "400",
            padding: "10px",
            backgroundColor: "white",
        },
    },
} ) );

export default ProductsOrderInformation
