import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Message = (props) => {
    const classes = useStyles();

    return (
        <Box className={ classes.root }>
            <Typography className={ classes.message }>
                { props.message }
            </Typography>
        </Box>
    )
}

const useStyles = makeStyles( () => ( {
    root: {
        padding: "18px 12px",

        borderRadius: 8,
        border: "1px solid #EAEAEA"
    },
    message: {
        fontSize: 24,
        lineHeight: "130%",
        letterSpacing: "0.1px",
        color: "#7D7D7D",
        textAlign: "center"
    }
} ) );

export default Message
