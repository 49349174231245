import React, { Component } from "react";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import {
    Actions as ActionsComponent,
    MainOrderInformation as MainOrderInformationComponent,
    ProductsOrderInformation as ProductsOrderInformationComponent
} from "./components";
import { DialogConfirmation } from "../../../../components"
import agent from "../../../../agent/agent";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import DialogSetStore from "./components/DialogSetStore/index";

class ListOrder extends Component {
    constructor(props) {
        super( props );

        this.state = {
            order: {},

            orderId: props?.match?.params?.id || null,

            settings: {},

            stores: [],

            isLoading: true,
            isNotFound: false,
            isShowBackdrop: false,
            isOpen: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {

        if (!this.state.orderId) {
            this.props.history.replace( `/orders` )
        }

        await this.getSettings();
        await this.getStores();
        await this.getOrder();
    }


    // Логика получения заказа
    getOrder = async () => {

        const orderId = this.state.orderId;

        const order = await agent.get( `/orders/${ orderId }` ).then( (res) => {
            return res.data?.order || null
        } ).catch( () => {
            return null
        } );
        if (!order) {
            this.setState( {
                isNotFound: true,
                isLoading: false
            } )

            return null
        }


        this.setState( {
            isLoading: false,
            order
        } );
    }

    getSettings = async () => {
        const settings = await agent.get( `/settings` )
            .then( (res) => res.data.settings )
            .catch( (err) => {
            } );

        this.setState( { settings } );
    }

    getStores = async () => {
        const { stores } = await agent.get( `/stores/get-by-user/${ this.props.user.id }` )
            .then( res => res.data )
            .catch( err => {
                return [];
            } );
        this.setState( {
            stores,
        } )
    }


    // Взятие зазаказа в работу
    takeWorkOrder = async (isConfirm) => {
        const order = this.state.order;

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите взять заказ №${ order.id } в работу?`,
                acceptButtonTitle: "Да, взять",
                acceptButtonAction: this.takeWorkOrder.bind( this, true )
            } );

            return null
        }

        this.setState( { isShowBackdrop: true } )

        const responsetakeWork = await agent.put( `/orders/${ order.id }/take-as-provider`, {
            storeId: order.storeId,
        } ).then( (res) => {
            return res.data
        } ).catch( (err) => {
            return { error: err.response }
        } );
        if (responsetakeWork.error) {
            this.setState( { isShowBackdrop: false } );

            const errorMessage = Boolean( responsetakeWork.error?.status === 403 ) ?
                "Нет прав" :
                responsetakeWork.error?.data?.message || "Возникла ошибка, попробуйте позднее";

            Notification( {
                type: NotificationTypes.error,
                message: errorMessage
            } );

            return null
        }

        this.setState( { isShowBackdrop: false } )

        Notification( {
            type: NotificationTypes.success,
            message: `Заказ №${ order.id } взят в работу`
        } );

        window.history.pushState( null, null, '/my-list' );
        this.props.history.push( `/my-list/order/${ order.id }` );

    }

    //Обновление заказа при указании склада
    setNewOrder = (newOrder) => {
        return this.setState( { order: newOrder } );
    }

    //Открытие модального окна с выбором склада
    setIsOpen = (isOpen) => {
        this.setState( { isOpen: isOpen } );
    }
    //Закрытие модального окна
    handleClose = () => {
        this.setIsOpen( false );
    }

    render() {
        const {
            order,

            orderId,

            settings,

            stores,

            isShowBackdrop,
            isLoading,
            isOpen
        } = this.state;

        return (
            <>

                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h1">
                            Заказ №{ orderId }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ActionsComponent
                            setIsOpen={ this.setIsOpen }
                        />
                    </Grid>
                </Grid>

                { !isLoading && (
                    <>
                        <MainOrderInformationComponent
                            order={ order }
                            settings={ settings }
                        />

                        <ProductsOrderInformationComponent
                            order={ order }
                            products={ ( order?.orderItems || [] ) }
                            systemPercent={ settings.systemPercent }
                        />
                    </>
                ) }


                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <DialogSetStore
                    order={ order }
                    stores={ stores }
                    handleClose={ this.handleClose }
                    isOpen={ isOpen }
                    takeWorkOrder={ this.takeWorkOrder }
                    setNewOrder={ this.setNewOrder }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

            </>
        );
    }
}

export default ListOrder
