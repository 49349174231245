import React, { Component } from "react";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import {
    ActiveDelegatedUrls,
    ContactInformation as ContactInformationComponent,
    DialogAcceptOrder as DialogAcceptOrderComponent,
    DialogAddBillOfLadingPhotos as DialogAddBillOfLadingPhotosComponent,
    DialogChoiceDeliveryActions,
    DialogFormCommentChange,
    DialogFormReadyToOrder as DialogFormReadyToOrderComponent,
    DialogFormSetFavoriteExpeditor,
    DialogFormSetSale,
    DialogFormTransferTheGoods,
    DialogPartialReturnForm,
    DialogPickupReturnConfirm as DialogPickupReturnConfirmComponent,
    DialogReturnConfirm as DialogReturnConfirmComponent,
    LifeCycle as LifeCycleComponent,
    MainOrderInformation as MainOrderInformationComponent,
    OrderPhotos as OrderPhotosComponent,
    ProductsOrderInformation as ProductsOrderInformationComponent,
    ReadyOrderInformation as ReadyOrderInformationComponent,
    ReceivedOrderInformation
} from "./components";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import { DialogConfirmation } from "../../../../components";
import { convertorNumber } from "../../../../helper/convertor";
import { providerPrice } from "../../../../helper/providerPrice";
import agent from "../../../../agent/agent";

class MyOrder extends Component {
    constructor(props) {
        super( props );

        this.state = {
            order: {},

            billOfLadings: [],
            orderReceipts: [],

            orderId: props?.match?.params?.id,
            returnItems: [],
            shortageItems: [],
            favoritesExpeditors: [],

            activeDelegatedUrls: [],

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],
            billOfLadingPhotos: [],

            settings: {},

            adminsPhones: [],

            isOpen: false,
            isOpenForm: false,
            isOpenComment: false,
            isOpenFormSetSale: false,
            isOpenChoiceDeliveryActions: false,
            isOpenReturnConfirm: false,
            isOpenPickupReturnConfirm: false,
            isOpenTransferTheGoods: false,
            isOpenPartialReturnForm: false,
            isOpenSetFavoriteExpeditor: false,
            isOpenAcceptOrder: false,
            isOpenAddBillOfLadingPhotos: false,
            isOpenReadyToOrder: false,

            isLoading: true,
            isLoadingBillOfLading: false,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingBillOfLadingPhotos: false,
            isLoadingReceipts: false,
            isLoadingActiveDelegatedUrls: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
        this.refDialogComment = React.createRef();
    }

    componentDidMount = async () => {
        await this.getAdminsPhones();
        await this.getSettings();
        await this.getReturnItems();
        await this.getShortageItems();
        await this.getFavoriteExpeditors();
        await this.getActiveDelegatedUrls();
        await this.getOrder();
    }

    // Получение заказа
    getOrder = async () => {

        const orderId = this.state.orderId;

        const order = await agent.get( `/orders/${ orderId }` ).then( (res) => {
            return res.data?.order || {}
        } ).catch( () => {
            return {}
        } );

        this.setState( {
            order,
            isLoading: false,
        } )
    }

    getSettings = async () => {
        const settings = await agent.get( `/settings` )
            .then( (res) => res.data.settings )
            .catch( (err) => {
            } );

        this.setState( { settings } );
    }

    // Получение возвращаемых товаров
    getReturnItems = async () => {
        const response = await agent.get( `/returns/${ this.state.orderId }/items` )
            .then( res => res.data.returnItems )
            .catch( err => [] );

        this.setState( {
            returnItems: response,
        } );
    };

    // Получение невозврат товаров
    getShortageItems = async () => {
        const response = await agent.get( `/returns/${ this.state.orderId }/shortage-items` )
            .then( res => res.data.shortageItems )
            .catch( err => {
                return [];
            } );

        this.setState( {
            shortageItems: response,
        } );
    };

    // Получение избранных экспедиторов
    getFavoriteExpeditors = async () => {
        const response = await agent.get( `/favorite-expeditor/get-by-provider` )
            .then( res => res.data.expeditors )
            .catch( err => [] );

        const favoritesExpeditors = response.map( item => item.expeditor );

        this.setState( {
            favoritesExpeditors,
        } );
    };

    // Логика получения накладной
    getBillOfLading = async () => {
        if (this.state.billOfLadings.length <= 0) {
            this.setState( { isLoadingBillOfLading: true } );

            const response = await agent.get( `orders/${ this.state.orderId }/bill-of-lading` )
                .then( res => res.data.pdfFile )
                .catch( err => {
                    return { error: err.response };
                } );

            if (response.error) {
                this.setState( {
                    isLoadingBillOfLading: false,
                    billOfLadings: [],
                } );

                Notification( {
                    message: response.error?.data?.message || "Ошибка при загрузке накладных",
                    type: NotificationTypes.error,
                } );

                return null;
            }

            let billOfLadingsUrls = [];

            if (Object.keys( response ).length <= 0) return null;

            const billOfLadingUrl = window.URL.createObjectURL( new Blob( [ new Uint8Array( response.data ).buffer ] ) );

            billOfLadingsUrls.push( billOfLadingUrl );

            this.setState( {
                isLoadingBillOfLading: false,
                billOfLadings: billOfLadingsUrls,
            } );
        }
    };

    // Логика получения чеков
    getOrderReceipts = async () => {
        if (this.state.orderReceipts.length <= 0) {
            this.setState( { isLoadingReceipts: true } );

            const response = await agent.get( `orders/${ this.state.orderId }/receipts` )
                .then( res => res.data.receipts )
                .catch( err => {
                    return { error: err.response };
                } );

            if (response.error) {
                this.setState( {
                    isLoadingReceipts: false,
                    orderReceipts: [],
                } );

                Notification( {
                    message: response.error?.data?.message || "Ошибка при загрузке чеков",
                    type: NotificationTypes.error,
                } );

                return null;
            }

            let receiptsUrls = [];

            if (Array.isArray( response ) && response.length > 0) {
                receiptsUrls = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.file.data ).buffer ] ) ) ) ];
            }

            this.setState( {
                isLoadingReceipts: false,
                orderReceipts: receiptsUrls,
            } );
        }
    };

    // Получение теелфонов администраторов
    getAdminsPhones = async () => {
        const response = await agent.get( 'users/admins-phones' )
            .then( res => res.data.phones )
            .catch( err => [] );

        this.setState( { adminsPhones: response } );
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos( type, true );

        const response = await agent.get( `/orders/${ this.state.orderId }/photos?neededType=${ type }` )
            .then( res => res.data.photos )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setLoadingStatusOrderPhotos( type, false );

            Notification( {
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            } );

            return null;
        }

        let newPhotos = [];

        if (Array.isArray( response ) && response.length > 0) {
            newPhotos = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.photo.data ).buffer ] ) ) ) ];
        }

        this.setState( { [type]: newPhotos } );

        this.setLoadingStatusOrderPhotos( type, false );

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification( {
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            } );
        }
    };

    // Логика прикрепления фотографий накладных
    attachBillOfLadingPhotos = async (photos, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите прикрепить фотографии?",
                acceptButtonTitle: "Да, прикрепить",
                acceptButtonAction: this.attachBillOfLadingPhotos.bind( this, photos, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const body = {
            orderId: this.state.orderId,
            billOfLadingPhotoInBase64: photos,
        };

        const response = await agent.post( `/orders/${ this.state.orderId }/attach-bill-of-lading`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при добавлении фотографий",
                type: NotificationTypes.error,
            } );

            return;
        }

        let newPhotos = [];

        if (Array.isArray( response.photos ) && response.photos.length > 0 && this.state.billOfLadingPhotos.length > 0) {
            newPhotos = [ ...response.photos.map( photo => window.URL.createObjectURL( new Blob( [ new Uint8Array( photo.data ).buffer ] ) ) ) ];
        }

        await this.getOrder();

        this.setState( {
            isShowBackdrop: false,
            billOfLadingPhotos: [ ...this.state.billOfLadingPhotos, ...newPhotos ],
        } );

        Notification( {
            message: "Фотографии успешно прикреплены",
            type: NotificationTypes.success,
        } );

    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState( { isLoadingProviderPhotos: status } );
        type === 'driverPhotos' && this.setState( { isLoadingDriverPhotos: status } );
        type === 'returnPhotos' && this.setState( { isLoadingReturnPhotos: status } );
        type === 'billOfLadingPhotos' && this.setState( { isLoadingBillOfLadingPhotos: status } );
    };

    //Подтверждение возврата (доставка)
    returnConfirm = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите подтвердить возврат?",
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.returnConfirm.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/returns/${ this.state.orderId }/return-confirmation`, form )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: response.error.data.message || "Ошибка подтверждения возврата",
                type: NotificationTypes.error
            } );

            return
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Возврат успешно подтвержден, сообщите код экспедитору или водителю",
            type: NotificationTypes.success,
        } );
    };

    // Подтверждение возврата
    pickupReturnConfirm = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить возврат?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.pickupReturnConfirm.bind( this, form, true )
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `returns/${ this.state.orderId }/return-confirmation`, {
            code: form.code,
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: response.error?.data?.message || "Ошибка при подтверждении возврата",
                type: NotificationTypes.error,
            } );

            return null;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        } );
    };

    // Логика работы по статусам
    setOrderStatus = async (status, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите изменить статус готовности заказа?`,
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.setOrderStatus.bind( this, status, true )
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        if (status === "partialReturn" || status === 'fullReturn') {
            const response = await agent.put( `/returns/${ this.state.order.id }`, {
                orderId: this.state.order.id,
                returnType: status,
            } ).then( (res) => {
                return res.data
            } ).catch( (err) => {
                return { error: err.response }
            } );
            if (response.error) {
                this.setState( { isShowBackdrop: false } );
                Notification( {
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                } )
                return
            }
            await this.getOrder();
            this.setState( { isShowBackdrop: false } );
            Notification( {
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен"
            } );
        } else {
            const response = await agent.put( `/orders/${ this.state.order.id }/set-status`, {
                orderId: this.state.order.id,
                deliveryStatus: status,
            } ).then( (res) => {
                return res.data
            } ).catch( (err) => {
                return { error: err.response }
            } );
            if (response.error) {
                this.setState( { isShowBackdrop: false } );

                Notification( {
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                } )

                return
            }

            await this.getOrder();

            this.setState( { isShowBackdrop: false } );

            Notification( {
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен",
            } );
        }
    }

    //Логика установки скидки
    setOrderSale = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно применить скидку к заказу?`,
                acceptButtonTitle: "Да, применить",
                acceptButtonAction: this.setOrderSale.bind( this, form, true ),
            } );

            return
        }
        this.setState( { isShowBackdrop: true } );
        const body = {
            orderId: +this.state.orderId,
            discountValue: +form.discountValue,
            userId: this.props.user.id,
        }
        const response = await agent.put( `/orders/${ this.state.orderId }/discount`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            let message = "Ошибка при установке скидки";

            if (response.error?.data?.message && !Array.isArray( response.error?.data?.message )) {
                message = response.error.data.message;
            }

            Notification( {
                type: NotificationTypes.error,
                message,
            } );

            return;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            type: NotificationTypes.success,
            message: "Скидка успешно применена",
        } )
    };

    //Логика передачи товара экспедитору\водителю
    transferTheGoods = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.transferTheGoods.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/orders/${ this.state.orderId }/transfer`, {
            orderPhotosInBase64: form.photo,
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } );
            Notification( {
                message: response.error?.data?.message || "Ошибка при передаче груза",
                type: NotificationTypes.error,
            } );
            return null;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Груз успешно передан, ожидайте подтверждения экспедитора/водителя",
            type: NotificationTypes.success,
        } );
    };

    // Отмена заказа
    cancelOrder = async (isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите отменить заказ?",
                acceptButtonTitle: "Да, отменить",
                acceptButtonAction: this.cancelOrder.bind( this, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/orders/${ this.state.order.id }/cancel` ).then( (res) => {
            return res.data
        } ).catch( (err) => {
            return { error: err.response }
        } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: response.error?.data?.message || "Ошибка отмены заказа, попробуйте позднее",
                type: NotificationTypes.error
            } );

            return
        }

        await this.getOrder()

        this.setState( { isShowBackdrop: false } )

        Notification( {
            message: "Заказ успешно отменен",
            type: NotificationTypes.success
        } );

        this.props.history.push( `/my-list` );

    }

    // Логика кнопки "Заказ принят"
    readyToOrder = async (form = {}, isConfirm) => {
        if (Object.keys( form ).length <= 0) {
            this.setIsOpenReadyToOrder( true );

            return;
        }

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно готовы передать заказ заказчику?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.readyToOrder.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        this.handleCloseReadyToOrder();

        const response = await agent.put( `/orders/${ this.state.orderId }/pick-up`, {
            orderPhotosInBase64: form?.photo || [],
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response };
            } )

        if (response.error) {
            this.setState( { isShowBackdrop: false } );
            Notification( {
                message: response.error?.data?.message || "Ошибка при подготовке передачи заказа",
                type: NotificationTypes.error,
            } )

            return null;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Передайте груз заказчику с помощью кода, который Вам сообщит заказчик",
            type: NotificationTypes.success,
        } );
    };

    // Передача груза заказчику
    acceptOrder = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз заказчику?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.acceptOrder.bind( this, code, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/orders/${ this.state.orderId }/confirm`, { code } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при передаче груза заказчику",
                type: NotificationTypes.error,
            } );

            return null;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getOrder();

        Notification( {
            message: "Груз успешно передан заказчику. Заказ считается выполненным",
            type: NotificationTypes.success,
        } );
    };

    //Частичный возврат (return-fill)
    partialReturn = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите провести частичный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.partialReturn.bind( this, form, true )
            } );

            return
        }
        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/returns/${ this.state.orderId }/return-fill`, form )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            } );

            return null
        }
        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            type: NotificationTypes.success,
            message: "Частичный возврат успешно проведен, ожидайте подтверждения от заказчика",
        } );
    };

    //Частичный возврат (return-fill)
    fullReturn = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите провести полный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.fullReturn.bind( this, true ),
            } );

            return
        }
        this.setState( { isShowBackdrop: true } );

        let orderItems = this.state.order?.orderItems || [];
        orderItems = orderItems.map( item => {
            return {
                orderItemId: item.id,
                quantity: convertorNumber( item.quantity, 4, '.' ),
            };
        } );

        const body = {
            returnItems: orderItems,
            productsPhotosInBase64: [],
        };

        const response = await agent.put( `/returns/${ this.state.orderId }/return-fill`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            } );

            return null
        }
        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            type: NotificationTypes.success,
            message: "Полный возврат успешно проведен, ожидайте подтверждения от заказчика",
        } );
    };

    saveComment = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите изменить комментарий?`,
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.saveComment.bind( this, form, true )
            } );

            return
        }
        this.setState( {
            isShowBackdrop: true,
        } )
        const response = await agent.put( `/orders/${ this.state.orderId }/set-comment`, form )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response };
            } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                type: NotificationTypes.error,
                message: response.error?.data?.message || "Ошибка сервера"
            } )

            return
        }

        await this.getOrder();

        this.setState( {
            isShowBackdrop: false,
            isOpenComment: false,
        } );

        Notification( {
            type: NotificationTypes.success,
            message: "Комментарий успешно изменен",
        } )

    };

    // Логика делегирования заказа
    getActiveDelegatedUrls = async () => {
        this.setState( { isLoadingActiveDelegatedUrls: true } );

        const response = await agent.get( `/delegated-orders/${ this.state.orderId }/active-delegated-orders-by-orderId` )
            .then( (res) => {
                return res.data.delegatedOrdersWithActionUrl;
            } )
            .catch( (err) => {
                return [];
            } );

        response.sort( (a, b) => b.id - a.id );

        this.setState( {
            isLoadingActiveDelegatedUrls: false,
            activeDelegatedUrls: response,
        } );
    };

    generateDelegatedUrl = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы точно хотите сгенерировать ссылку для делегирования?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, сгенерировать",
                acceptButtonAction: this.generateDelegatedUrl.bind( this, true ),
            } );

            return;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.post( `/delegated-orders`, {
            orderId: +this.state.orderId,
        } )
            .then( (res) => {
                console.log( "res.data: ", res.data );

                return res.data.hash;
            } )
            .catch( (err) => {
                return {
                    error: err.response,
                }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || 'Ошибка при генерации ссылки для делегирования',
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getActiveDelegatedUrls();

        Notification( {
            message: "Ссылка для делегирования успешно сгенерирована",
            type: NotificationTypes.success,
        } );
    };

    deactivateActiveDelegatedUrl = async (delegatedUrlId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы точно хотите деактивировать ссылку для делегирования?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, деактивировать",
                acceptButtonAction: this.deactivateActiveDelegatedUrl.bind( this, delegatedUrlId, true ),
            } );

            return;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/delegated-orders/${ delegatedUrlId }/deactivate` )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при деактивации ссылки для делегирования",
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getActiveDelegatedUrls();

        Notification( {
            message: "Ссылка для делегирования успешно деактивирована",
            type: NotificationTypes.success,
        } );

    };

    // Логика установки своего экспедитора
    setFavoriteExpeditorToOrder = async (expeditorId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите назначить своего экспедитора?`,
                acceptButtonTitle: "Да, назначить",
                acceptButtonAction: this.setFavoriteExpeditorToOrder.bind( this, expeditorId, true )
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/orders/${ this.state.orderId }/set-favorite-courier`, {
            expeditorId,
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );
            Notification( {
                message: response.error?.data?.message || "Ошибка при назначении своего экспедитора",
                type: NotificationTypes.error,
            } )

            return null;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getOrder();

        Notification( {
            message: "Экспедитор успешно назначен",
            type: NotificationTypes.success,
        } );
    };

    // Получение статусов по заказу
    _statusItems = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || ""
        const deliveryMethod = this.state.order?.deliveryMethod || "";

        if (deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        let orderStatuses = [
            {
                label: "Принят в работу",
                active: true,
                error: false,
            },
            {
                label: "Частично готов",
                active: [ "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Готов",
                active: [ "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Ожидает погрузки",
                active: [ 'courierInstalled', 'loaded', "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Загружен",
                active: [ "enRoute", "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Доставлен",
                active: [ "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ? "Не выполнен" : "Выполнен" ),
                active: [ "received" ].includes( deliveryStatus ) || ( [ 'partialReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
                error: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
            }
        ];

        return orderStatuses;
    }

    // Получение кнопок управление "Производство"
    _buttonReady = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || "";
        const deliveryMethod = this.state.order?.deliveryMethod || "";
        let buttons = [];


        // Показывать ли кнопку "Заказ частично готов"
        const isHideReadyForShipment = [ "partiallyReady", "readyForShipment", "dutyWarehouse", "loaded", "received", "partialReturn", "fullReturn", 'enRoute', 'waitConfirmFromDelivery' ].includes( deliveryStatus );
        if (!isHideReadyForShipment) {
            buttons.push( {
                label: "Заказ готов частично",
                onClick: this.setOrderStatus.bind( this, "partiallyReady", false ),
                disable: ""
            } )
        }


        // Показывать ли кнопку "Заказ готов"
        const isHidePartiallyReady = [ "readyForShipment", "dutyWarehouse", "loaded", "received", "partialReturn", "fullReturn", 'enRoute', 'waitConfirmFromDelivery' ].includes( deliveryStatus );
        if (!isHidePartiallyReady) {
            buttons.push( {
                label: "Заказ готов",
                onClick: deliveryMethod === 'delivery' ? this.setIsOpenChoiceDeliveryActions.bind( this, true ) : this.setOrderStatus.bind( this, "readyForShipment", false ),
                disable: ""
            } );
        }

        const isShowSaleBtn = [ 'partialReturn', 'fullReturn' ].includes( deliveryStatus ) && this.state.order.statusOfReturnOrder === 'created';
        if (isShowSaleBtn) {
            buttons.push( {
                label: "Скидка",
                onClick: this.setIsOpenFormSetSale.bind( this, true ),
                disable: ""
            } )
        }

        const isShowReturnConfirm = [ 'partialReturn', 'fullReturn' ].includes( deliveryStatus ) && this.state.order.statusOfReturnOrder === 'transferredToProvider';
        if (isShowReturnConfirm) {
            buttons.push( {
                label: "Подтвердить возврат",
                onClick: this.setIsOpenReturnConfirm.bind( this, true ),
                disable: "",
            } )
        }

        // Показывать кнопку "ПЕРЕДАТЬ ГРУЗ"
        const isShowTransferTheGoods = [ 'loaded' ].includes( deliveryStatus );
        if (isShowTransferTheGoods) {
            buttons.push( {
                label: "Передать груз",
                onClick: this.setIsOpenTransferTheGoods.bind( this, true ),
                disable: ""
            } )
        }
        return buttons;
    };
    //Получение кнопок управление "Отгрузка"
    _buttonReceived = () => {
        const { order } = this.state;
        const deliveryStatus = order?.deliveryStatus || "";
        let buttons = [];

        // Показывать ли кнопку "Заказ готов"
        const isHidePartiallyReady = [ "readyForShipment", "dutyWarehouse", "loaded", "received", "partialReturn", "fullReturn", 'enRoute', 'waitConfirmFromDelivery', 'transferringToClient' ].includes( deliveryStatus );
        if (!isHidePartiallyReady) {
            buttons.push( {
                label: "Заказ готов",
                onClick: this.setOrderStatus.bind( this, "readyForShipment", false ),
                disable: ""
            } );
        }

        // Показывать ли кнопку "Заказ принят"
        const isHideReceived = [ 'partiallyReady', "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus );
        if (!isHideReceived && order.statusOfReturnOrder === null) {
            buttons.push( {
                label: "Готов передать заказ",
                onClick: this.readyToOrder.bind( this, {}, false ),
                disable: ""
            } )
        }

        // Завершение заказа (передача заказа заказчику)
        if ([
            "transferringToClient",
        ].includes( deliveryStatus )) {
            buttons.push( { label: "Передать заказ", onClick: this.setIsOpenAcceptOrder.bind( this, true ) } );
        }

        //Показывать ли кнопку "Полный возврат"
        const isHideFullReturn = [ 'partiallyReady', "received", "partialReturn", "fullReturn" ].includes( deliveryStatus );
        if (!isHideFullReturn && order.statusOfReturnOrder === null) {
            buttons.push( {
                label: "Полный возврат",
                onClick: this.setOrderStatus.bind( this, "fullReturn", false ),
                disable: ""
            } )
        }

        // Показывать ли кнопку "Частичный возврат"
        const isHidePartialReturn = [ 'partiallyReady', "received", "partialReturn", "fullReturn" ].includes( deliveryStatus );
        if (!isHidePartialReturn) {
            buttons.push( {
                label: "Частичный возврат",
                onClick: this.setOrderStatus.bind( this, "partialReturn", false ),
                disable: ""
            } )
        }

        //Частичный возврат (возврат товаров)
        if (deliveryStatus === 'partialReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push( { label: "Возврат", onClick: this.setIsOpenPartialReturn.bind( this, true ) } );
        }

        // Полный возврат (возврат товаров)
        if (deliveryStatus === 'fullReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push( { label: "Возврат", onClick: this.fullReturn.bind( this, false ) } );
        }

        // Подтверждение возврата
        if ([ 'partialReturn', 'fullReturn' ].includes( deliveryStatus ) && [ 'purchaserConfirmed' ].includes( order.statusOfReturnOrder )) {
            buttons.push( {
                label: "Подтвердить возврат",
                onClick: this.setIsOpenPickupReturnConfirm.bind( this, true )
            } );
        }

        return buttons;
    }
    // Скрыть кнопку отказа от заказа
    _isShowCancelOrder = () => {
        const deliveryStatus = this.state.order.deliveryStatus;

        if (deliveryStatus === "canceled") {
            return false
        }

        return [ "new", 'inProgress', 'partiallyReady', 'notReadyForShipment' ].includes( this.state.order.deliveryStatus );

    }
    _isVisibleActionsReady = () => {
        return ( ( this.state.order.deliveryMethod === 'delivery' && ( [ "inProgress", 'partiallyReady', 'loaded', ].includes( this.state.order.deliveryStatus ) || [ 'created', 'transferredToProvider' ].includes( this.state.order.statusOfReturnOrder ) ) ) || [ "inProgress" ].includes( this.state.order.deliveryStatus ) );
    };
    _isVisibleActionsReceived = () => {
        return ( ( [ 'partiallyReady', 'readyForShipment', 'transferringToClient' ].includes( this.state.order.deliveryStatus ) && this.state.order.deliveryMethod === "pickup" ) || ( this.state.order.deliveryMethod === 'pickup' && [ 'created', 'purchaserConfirmed' ].includes( this.state.order.statusOfReturnOrder ) ) );
    };

    //Открытие модального окна частичного возврата
    setIsOpenForm = (isOpen) => {
        this.setState( { isOpenForm: isOpen } );
    };
    //Закрытие модального окна частичного возврата
    handleCloseForm = () => {
        this.setIsOpenForm( false );
    }

    //Открытие формы с комментарием
    setIsOpenComment = (isOpen) => {
        this.setState( { isOpenComment: isOpen } )
    };
    //Закрытие формы с комментарием
    handleCloseComment = () => {
        this.setIsOpenComment( false );
    }

    //Открытие формы скидки
    setIsOpenFormSetSale = (isOpen) => {
        this.setState( {
            isOpenFormSetSale: isOpen,
        } );
    };
    //Закрытие формы скидки
    handleCloseFormSetSale = () => {
        this.setIsOpenFormSetSale( false );
    }

    // Открытие формы подтверждения возврата
    setIsOpenReturnConfirm = (isOpen) => {
        this.setState( { isOpenReturnConfirm: isOpen } );
    };
    // Закрытие формы подтверждения заказа
    handleCloseReturnConfirm = () => {
        this.setIsOpenReturnConfirm( false );
    };

    // Открытие формы подтверждения заказа (самовывоз)
    setIsOpenPickupReturnConfirm = (isOpen) => {
        this.setState( { isOpenPickupReturnConfirm: isOpen } );
    };
    // Закрытие формы подтверждения заказа (самовывоз)
    handleClosePickupReturnConfirm = () => {
        this.setIsOpenPickupReturnConfirm( false );
    };

    // Открытие формы передачи груза
    setIsOpenTransferTheGoods = (isOpen) => {
        this.setState( {
            isOpenTransferTheGoods: isOpen,
        } )
    };
    // Закрытие формы передачи груза
    handleCloseTransferTheGoods = () => {
        this.setIsOpenTransferTheGoods( false );
    };

    //Логика открытия модального окна частичного возврата (самовывоз)
    setIsOpenPartialReturn = (isOpen) => {
        this.setState( { isOpenPartialReturnForm: isOpen, } );
    };
    //Закрытие модального окна частичного возврата (самовывоз)
    handleClosePartialReturn = () => {
        this.setIsOpenPartialReturn( false );
    };

    // Логика открытия формы выбора доставки (избранный экспедитор или в ленту экспедиторов)
    setIsOpenChoiceDeliveryActions = (isOpen) => {
        this.setState( {
            isOpenChoiceDeliveryActions: isOpen,
        } )
    };
    // Логика открытия формы выбора доставки (избранный экспедитор или в ленту экспедиторов)
    handleCloseIsOpenChoiceDeliveryActions = () => {
        this.setIsOpenChoiceDeliveryActions( false );
    };

    // Логика открытия формы установки своего экспедитора
    setIsOpenSetFavoriteExpeditor = (isOpen) => {
        this.setState( {
            isOpenSetFavoriteExpeditor: isOpen,
        } )
    };
    //Логика закрытия формы установки своего экспедитора
    handleCloseIsOpenSetFavoriteExpeditor = () => {
        this.setIsOpenSetFavoriteExpeditor( false );
    };

    // Открытие формы завершение заказа (передачи заказа заказчику)
    setIsOpenAcceptOrder = (isOpen) => {
        this.setState( {
            isOpenAcceptOrder: isOpen,
        } );
    };
    // Закрытие формы завершения заказа (передачи заказа заказчику)
    handleCloseAcceptOrder = () => {
        this.setIsOpenAcceptOrder( false );
    };

    //Открытие формы добавления фотографии накладных
    setIsOpenAddBillOfLadingPhotos = (isOpen) => {
        this.setState( { isOpenAddBillOfLadingPhotos: isOpen } );
    };
    // Закрытие формы добавления фотографии накладных
    handleCloseAddBillOfLadingPhotos = () => {
        this.setIsOpenAddBillOfLadingPhotos( false );
    };

    // Открытие формы подготовки к передаче заказа
    setIsOpenReadyToOrder = (isOpen) => {
        this.setState( {
            isOpenReadyToOrder: isOpen,
        } );
    };
    // Закрытие формы подготовки к передаче заказа
    handleCloseReadyToOrder = () => {
        this.setIsOpenReadyToOrder( false );
    };


    render() {
        const {
            order,
            billOfLadings,
            orderReceipts,
            returnItems,
            shortageItems,
            favoritesExpeditors,
            orderId,
            settings,
            adminsPhones,

            activeDelegatedUrls,

            providerPhotos,
            driverPhotos,
            returnPhotos,
            billOfLadingPhotos,

            isLoading,
            isLoadingBillOfLading,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingBillOfLadingPhotos,
            isLoadingReceipts,
            isLoadingActiveDelegatedUrls,

            isShowBackdrop,

            isOpen,
            isOpenComment,
            isOpenFormSetSale,
            isOpenForm,
            isOpenReturnConfirm,
            isOpenPartialReturnForm,
            isOpenTransferTheGoods,
            isOpenChoiceDeliveryActions,
            isOpenSetFavoriteExpeditor,
            isOpenAcceptOrder,
            isOpenPickupReturnConfirm,
            isOpenAddBillOfLadingPhotos,
            isOpenReadyToOrder,
        } = this.state;
        const {
            stores
        } = this.props;

        return (
            <>

                <Box mb={ 2 }>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center" spacing={ 2 }>
                                <Grid item>
                                    <Typography variant="h1">
                                        Заказ №{ orderId }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LifeCycleComponent
                                        items={ this._statusItems() }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            { Boolean( this._isShowCancelOrder() ) && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={ { height: 40, borderRadius: 0 } }
                                    onClick={ () => this.cancelOrder() }
                                >
                                    Отказаться от заказа
                                </Button>
                            ) }
                        </Grid>
                    </Grid>
                </Box>

                { Boolean( !isLoading ) && (
                    <>
                        <MainOrderInformationComponent
                            order={ order }
                            orderReceipts={ orderReceipts }
                            billOfLadings={ billOfLadings }
                            stores={ stores }
                            settings={ settings }

                            isLoadingBillOfLading={ isLoadingBillOfLading }
                            isLoadingReceipts={ isLoadingReceipts }

                            setIsOpen={ this.setIsOpen }
                            setIsOpenComment={ this.setIsOpenComment }
                            setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                            onGetBillOfLading={ this.getBillOfLading }
                            onGetReceipts={ this.getOrderReceipts }
                        />

                        <ContactInformationComponent
                            order={ order }
                            adminsPhones={ adminsPhones }
                        />

                        <ProductsOrderInformationComponent
                            order={ order }
                            products={ ( order?.orderItems || [] ) }
                            systemPercent={ settings.systemPercent }
                            returnItems={ returnItems }
                            shortageItems={ shortageItems }
                        />

                        { ( order.attachedPhotosFlags &&
                            (
                                order.attachedPhotosFlags.billOfLadingPhotos ||
                                order.attachedPhotosFlags.fromDriver ||
                                order.attachedPhotosFlags.fromProvider
                            )
                        ) && (
                            <OrderPhotosComponent
                                order={ order }
                                orderDeliveryStatus={ this.state.order?.deliveryStatus || "" }
                                attachedPhotoFlags={ order.attachedPhotosFlags }

                                providerPhotos={ providerPhotos }
                                driverPhotos={ driverPhotos }
                                returnPhotos={ returnPhotos }
                                billOfLadingPhotos={ billOfLadingPhotos }

                                isLoadingProviderPhotos={ isLoadingProviderPhotos }
                                isLoadingDriverPhotos={ isLoadingDriverPhotos }
                                isLoadingReturnPhotos={ isLoadingReturnPhotos }
                                isLoadingBillOfLadingPhotos={ isLoadingBillOfLadingPhotos }

                                getPhotos={ this.getOrderPhotosUsingType }

                                setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                            />
                        ) }

                        <ActiveDelegatedUrls
                            activeDelegatedUrls={ activeDelegatedUrls }
                            isLoadingActiveDelegatedUrls={ isLoadingActiveDelegatedUrls }

                            onGenerateLink={ this.generateDelegatedUrl }
                            onDeactivateLink={ this.deactivateActiveDelegatedUrl }
                        />

                        <DialogFormCommentChange
                            order={ order }
                            refFormik={ this.refDialogComment }
                            isOpen={ isOpenComment }
                            onSave={ this.saveComment }
                            onClose={ this.handleCloseComment }
                        />

                        <DialogFormSetSale
                            orderPrice={ providerPrice( order?.sum || 0, settings?.systemPercent || 0 ) }
                            isOpen={ isOpenFormSetSale }
                            onClose={ this.handleCloseFormSetSale }
                            onSetOrderSale={ this.setOrderSale }
                        />

                        { ( [ 'transferredToProvider' ].includes( order.statusOfReturnOrder ) ) && (
                            <DialogReturnConfirmComponent
                                order={ order }
                                returnItems={ returnItems }
                                isOpen={ isOpenReturnConfirm }
                                onClose={ this.handleCloseReturnConfirm }
                                onConfirm={ this.returnConfirm }
                            />
                        ) }

                        <DialogFormTransferTheGoods
                            order={ order }
                            isOpen={ isOpenTransferTheGoods }
                            onClose={ this.handleCloseTransferTheGoods }
                            onTransfer={ this.transferTheGoods }
                        />

                        { this._isVisibleActionsReady() && (
                            <ReadyOrderInformationComponent
                                actions={ this._buttonReady() }
                            />
                        ) }

                        { this._isVisibleActionsReceived()
                            ? (
                                <>
                                    <ReceivedOrderInformation actions={ this._buttonReceived() }/>
                                    <DialogPartialReturnForm
                                        initialValues={ order }
                                        isOpen={ isOpenPartialReturnForm }

                                        handleClose={ this.handleClosePartialReturn }
                                        onPartialReturn={ this.partialReturn }
                                    />
                                </>
                            )
                            : null
                        }

                        <DialogAcceptOrderComponent
                            isOpen={ isOpenAcceptOrder }
                            onClose={ this.handleCloseAcceptOrder }
                            onAccept={ this.acceptOrder }
                        />

                        <DialogFormReadyToOrderComponent
                            isOpen={ isOpenReadyToOrder }
                            onClose={ this.handleCloseReadyToOrder }
                            onReady={ this.readyToOrder }
                        />
                    </>
                ) }

                <DialogPickupReturnConfirmComponent
                    isOpen={ isOpenPickupReturnConfirm }
                    onClose={ this.handleClosePickupReturnConfirm }
                    onConfirm={ this.pickupReturnConfirm }
                />

                <DialogChoiceDeliveryActions
                    isOpen={ isOpenChoiceDeliveryActions }
                    onClose={ this.handleCloseIsOpenChoiceDeliveryActions }
                    onSetFavoriteExpeditor={ this.setIsOpenSetFavoriteExpeditor }
                    onRouteToTheExpeditorsList={ this.setOrderStatus.bind( this, 'readyForShipment', false ) }
                />

                <DialogFormSetFavoriteExpeditor
                    expeditors={ favoritesExpeditors }
                    isOpen={ isOpenSetFavoriteExpeditor }
                    onSetExpeditor={ this.setFavoriteExpeditorToOrder }
                    onClose={ this.handleCloseIsOpenSetFavoriteExpeditor }
                />

                <DialogAddBillOfLadingPhotosComponent
                    isOpen={ isOpenAddBillOfLadingPhotos }

                    onAttach={ this.attachBillOfLadingPhotos }
                    onClose={ this.handleCloseAddBillOfLadingPhotos }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />


                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>

            </>
        );
    }
}

export default MyOrder
