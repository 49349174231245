import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

const initialValues = {
    providerComment: "",
}

const DialogFormCommentChange = (props) => {
    const {
        order,
        refFormik,
        isOpen,
        onSave,
        onClose
    } = props;
    const [ isCreate, setIsCreate ] = useState( Boolean( !order.providerComment ) );

    const onSubmit = (form) => {
        const newForm = {
            comment: form.providerComment,
        }

        onSave( newForm );
    };

    const handleChange = ({ target }) => {
        const { value, name } = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues( newForm );
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }

            onClose={ onClose }
        >
            <DialogTitle>
                <Typography
                    variant="h3">{ isCreate ? "Создание комментария" : "Редактирование комментария" }</Typography>
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ isCreate ? initialValues : order }
                    validationSchema={ validateSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box mt={ 1 }>
                                    <Box>
                                        <TextField
                                            multiline
                                            fullWidth
                                            size="small"
                                            rows={ 7 }
                                            value={ values.providerComment }
                                            error={ touched.providerComment && Boolean( errors.providerComment ) }
                                            helperText={ touched.providerComment && errors.providerComment }
                                            label="Комментарий"
                                            name="providerComment"
                                            variant="outlined"

                                            onChange={ handleChange }
                                        />
                                    </Box>
                                </Box>
                                <DialogActions>
                                    <Box mt={ 2 }>
                                        <Grid container justifyContent="flex-end" spacing={ 1 }>
                                            <Grid item>
                                                <Button variant="outlined" onClick={ onClose }
                                                        sx={ {
                                                            textTransform: "initial",
                                                            borderRadius: "4px",
                                                            padding: "4px 24px",
                                                        } }>
                                                    Отменить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={ handleSubmit }>
                                                    { isCreate ? "Добавить" : "Изменить" }
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validateSchema = Yup.object().shape( {
    providerComment: Yup.string().required( 'Выберите дату' ),
} )

export default DialogFormCommentChange;
