import React, { useState } from "react";
import { Box, Button, Collapse, Link, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import { convertorNumber } from "../../../../../../helper/convertor";
import { LoadingButton } from "@mui/lab";
import Map from "../../../../../../components/Map";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import clsx from "clsx";
import moment from "moment";

const MainOrderInformation = (props) => {
    const {
        order,
        billOfLadings,
        orderReceipts,
        settings,

        isLoadingBillOfLading,
        isLoadingReceipts,

        setIsOpen,
        setIsOpenComment,
        setIsOpenAddBillOfLadingPhotos,
        onGetBillOfLading,
        onGetReceipts,
    } = props;
    const classes = useStyles();
    const [ isShowMap, setIsShowMap ] = useState( false );
    const [ billOfLadingAnchorEl, setBillOfLadingAnchorEl ] = React.useState( null );
    const [ isOpenBillOfLading, setIsOpenBillOfLading ] = React.useState( null );
    const [ receiptsAnchorEl, setReceiptsAnchorEl ] = React.useState( null );
    const [ isOpenReceipts, setIsOpenReceipts ] = React.useState( false );
    const store = order?.store;

    const handleGetBillOfLading = async (event) => {
        setBillOfLadingAnchorEl( event.currentTarget );

        await onGetBillOfLading();

        setIsOpenBillOfLading( true );
    }

    const handleGetReceipts = async (event) => {
        setReceiptsAnchorEl( event.currentTarget );

        await onGetReceipts();

        setIsOpenReceipts( true );
    };

    const handleCloseBillOfLading = () => {
        setBillOfLadingAnchorEl( null );
        setIsOpenBillOfLading( false );
    };

    const handleCloseReceipts = () => {
        setReceiptsAnchorEl( null );
        setIsOpenReceipts( false );
    };

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }
    const _deliveryDate = () => {
        const date = moment( order?.deliveryDate ).format( "DD.MM.YYYY" );
        const timesDelivery = deliveryTime( settings );
        const timespan = timesDelivery.filter( time => time.value === order?.deliveryTimespan );

        return `${ date } (${ timespan[0]?.label || "Время не указано" })`;
    }
    const _deliveryAddress = () => {
        return order?.deliveryAddress || "Милицейский адрес не найден";
    }

    const _openMap = () => {
        window.open( `https://www.google.ru/maps/search/@${ store?.addressLat },${ store?.addressLong },12z` )
    }

    const openModal = () => {
        setIsOpen( true );
    }

    return (
        <Box className={ classes.root }>

            <Typography className={ classes.title }>
                Основная информация о заказе
            </Typography>

            <Box className={ classes.content }>

                <Box className={ classes.columnContent }>
                    <Typography className={ classes.towLabel }>Дата/время доставки</Typography>

                    <Typography className={ classes.towValue }>
                        { _deliveryDate() }
                    </Typography>
                </Box>

                <Box className={ classes.columnContent }>
                    <Typography className={ classes.towLabel }>Способ доставки</Typography>
                    <Typography className={ classes.towValue }>
                        { _getDeliveryMethod( order.deliveryMethod ) }
                    </Typography>
                </Box>

                { order.deliveryMethod === "delivery" && (
                    <Box className={ classes.columnContent }>
                        <Typography className={ classes.towLabel }>Адрес доставки</Typography>

                        <Box className={ classes.rowContent }>
                            <Typography className={ classes.towValue }>
                                { _deliveryAddress() }
                            </Typography>

                            <Button
                                className={ classes.buttonChangeDeliveryDate }
                                color="primary"
                                variant="contained"

                                onClick={ () => setIsShowMap( !isShowMap ) }
                            >
                                Развернуть карту
                            </Button>
                        </Box>
                    </Box>
                ) }

                <Box className={ classes.columnContent }>
                    <Typography className={ classes.storeInfoTitle }>Склад</Typography>
                    <Typography className={ classes.storeInfoValue }>
                        { store?.name }
                    </Typography>
                </Box>

                { order.confirmationCode && (
                    <Box className={ classes.columnContent }>
                        <Typography className={ classes.towLabel }>Код подтверждения</Typography>
                        <Typography className={ classes.towValue }>
                            { order.confirmationCode }
                        </Typography>
                    </Box>
                ) }

                { order.discount > 0 && (
                    <Box className={ classes.columnContent }>
                        <Typography className={ clsx( {
                            [classes.towLabel]: true,
                            [classes.discountLabel]: true,
                        } ) }>Скидка</Typography>
                        <Typography className={ clsx( {
                            [classes.towValue]: true,
                            [classes.discountValue]: true,
                        } ) }>
                            { convertorNumber( order.discount, 2 ) } ₽
                        </Typography>
                    </Box>
                ) }

                { order.adminDiscount > 0 && (
                    <Box className={ classes.columnContent }>
                        <Typography className={ clsx( {
                            [classes.towLabel]: true,
                            [classes.discountLabel]: true,
                        } ) }>Скидка администратора</Typography>
                        <Typography className={ clsx( {
                            [classes.towValue]: true,
                            [classes.discountValue]: true,
                        } ) }>
                            { convertorNumber( order.adminDiscount, 2 ) } ₽
                        </Typography>
                    </Box>
                ) }

                { ( order.paymentStatus !== 'new' && order.deliveryMethod === 'pickup' ) && (
                    <Box className={ classes.columnContent }>
                        <Typography className={ classes.towLabel }>Чеки</Typography>
                        <Box className={ classes.rowContent }>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                sx={ {
                                    padding: "0 8px",
                                    minHeight: "initial",
                                    height: 30,
                                    borderRadius: 1,
                                } }
                                loading={ isLoadingReceipts }
                                id="receipts-basic-button"
                                aria-controls={ isOpenReceipts ? 'receipts-basic-menu' : undefined }
                                aria-haspopup="true"
                                aria-expanded={ isOpenReceipts ? 'true' : undefined }
                                onClick={ handleGetReceipts }
                            >
                                Скачать
                            </LoadingButton>
                            <Menu
                                id="receipts-basic-menu"
                                anchorEl={ receiptsAnchorEl }
                                open={ isOpenReceipts }
                                onClose={ handleCloseReceipts }
                                MenuListProps={ {
                                    'aria-labelledby': 'receipts-basic-button',
                                } }
                            >
                                { orderReceipts.length > 0 && (
                                    <>
                                        { orderReceipts.map( (receipt, index) => (
                                            <MenuItem key={ `order-receipt-index-${ index }` }>
                                                <Button
                                                    disableRipple
                                                    disableFocusRipple
                                                    disableTouchRipple
                                                    disableElevation
                                                    className={ classes.menuLinkButton }
                                                    component={ Link }
                                                    href={ receipt }
                                                    download={ `ZAKAZ-№${ order.id }-RECEIPT-${ index + 1 }.pdf` }
                                                >
                                                    Чек №{ index + 1 }
                                                </Button>
                                            </MenuItem>
                                        ) ) }
                                    </>
                                ) }
                                { orderReceipts.length <= 0 && (
                                    <MenuItem disabled>
                                        Чеков по этому заказу нет. Попробуйте позже или обратитесь к администратору
                                    </MenuItem>
                                ) }
                            </Menu>
                        </Box>
                    </Box>
                ) }

                {
                    ( ![ 'new', 'inProgress', 'partiallyReady', 'readyForShipment', 'courierInstalled', 'loaded' ].includes( order.deliveryStatus ) )
                    && (
                        <Box className={ classes.columnContent }>
                            <Typography className={ classes.towLabel }>
                                Накладные
                            </Typography>

                            <Box className={ classes.rowContent }>
                                <LoadingButton
                                    className={ classes.button }
                                    variant="contained"
                                    color="primary"
                                    sx={ {
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                    } }
                                    loading={ isLoadingBillOfLading }
                                    id="bill-of-ladings-basic-button"
                                    aria-controls={ isOpenBillOfLading ? 'bill-of-ladings-basic-menu' : undefined }
                                    aria-haspopup="true"
                                    aria-expanded={ isOpenBillOfLading ? 'true' : undefined }
                                    onClick={ handleGetBillOfLading }
                                >
                                    Скачать
                                </LoadingButton>
                                <Menu
                                    id="bill-of-ladings-basic-menu"
                                    anchorEl={ billOfLadingAnchorEl }
                                    open={ isOpenBillOfLading }
                                    onClose={ handleCloseBillOfLading }
                                    MenuListProps={ {
                                        'aria-labelledby': 'bill-of-ladings-basic-button',
                                    } }
                                >
                                    { billOfLadings.length > 0 && (
                                        <>
                                            { billOfLadings.map( (lading, index) => (
                                                <MenuItem key={ `order-bill-of-lading-index-${ index }` }>
                                                    <Button
                                                        disableRipple
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        disableElevation
                                                        className={ classes.menuLinkButton }
                                                        component={ Link }
                                                        href={ lading }
                                                        download={ `ZAKAZ-№${ order.id }-NAKLADNAYA-${ index + 1 }.pdf` }
                                                    >
                                                        Накладная №{ index + 1 }
                                                    </Button>
                                                </MenuItem>
                                            ) ) }
                                        </>
                                    ) }
                                    { billOfLadings.length <= 0 && (
                                        <MenuItem disabled>
                                            Накладных по этому заказу нет. Попробуйте позже или обратитесь к администратору
                                        </MenuItem>
                                    ) }
                                </Menu>

                                { (
                                    ( ( order.deliveryMethod === 'pickup' && order.deliveryStatus === 'received' ) || [ 'fullReturn', 'partialReturn' ].includes( order.deliveryStatus ) ) &&
                                    ( !order?.attachedPhotosFlags?.billOfLadingPhotos )
                                ) && (
                                    <Tooltip title="Прикрепить фотографии накладных на которых поставлены печати">
                                        <Button
                                            className={ classes.button }
                                            variant="contained"
                                            color="primary"
                                            sx={ {
                                                padding: "0 8px",
                                                minHeight: "initial",
                                                height: 30,
                                                borderRadius: 1,
                                            } }

                                            onClick={ setIsOpenAddBillOfLadingPhotos.bind( this, true ) }
                                        >
                                            Добавить
                                        </Button>
                                    </Tooltip>
                                ) }
                            </Box>
                        </Box>
                    )
                }

                { order.providerComment && order.providerComment !== ""
                    ? (
                        <Box className={ classes.columnContent }>
                            <Box className={ classes.rowContent }>
                                <Typography
                                    className={ classes.towLabel }
                                    sx={ {
                                        marginBottom: "0 !important",
                                    } }
                                >
                                    Комментарий
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={ classes.buttonChangeDeliveryDate }

                                    onClick={ setIsOpenComment.bind( this, true ) }
                                >
                                    Изменить
                                </Button>
                            </Box>

                            <Typography mt={ 1 } className={ classes.towValue }>
                                { order?.providerComment }
                            </Typography>
                        </Box>
                    )
                    : (
                        <>
                            <Box className={ classes.columnContent }>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={ {
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                    } }

                                    onClick={ setIsOpenComment.bind( this, true ) }
                                >
                                    Добавить комментарий
                                </Button>
                            </Box>
                        </>
                    )
                }
            </Box>

            { order.deliveryMethod === "delivery" && (
                <Collapse in={ isShowMap } timeout="auto" sx={ { marginTop: "20px" } }>
                    <Box className={ classes.content }>
                        <Box className={ classes.row } mb={ 1 }>
                            <Typography className={ classes.towLabel }>Широта</Typography>
                            <Typography className={ classes.towValue }>
                                { order?.deliveryAddressLat || "-" }
                            </Typography>
                        </Box>

                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Долгота</Typography>
                            <Typography className={ classes.towValue }>
                                { order?.deliveryAddressLong || "-" }
                            </Typography>
                        </Box>
                    </Box>
                    <Box height={ 420 }>
                        <Map
                            center={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                            coords={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                        />
                    </Box>
                </Collapse>
            ) }

        </Box>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 32
    },
    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)"
        }
    },

    columnContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 16,
    },

    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },

    discountLabel: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "normal",
    },
    discountValue: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "normal",
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
    },


    store: {},
    storeTitle: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
        fontWeight: "500",
        marginBottom: 16
    },
    storeInfo: {},
    storeInfoTitle: {
        fontSize: 16,
        lineHeight: "16px",
        color: "black",
        opacity: 0.6,
        marginBottom: 8
    },
    storeInfoValue: {
        fontSize: 18,
        lineHeight: "20px",
        color: "black",
        fontWeight: "500"
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },

    menuLinkButton: {
        fontWeight: 400,
        padding: "0 8px",
        minHeight: "initial",
        height: "auto",
        borderRadius: 1,
        color: "#000000",
    },
} ) );

export default MainOrderInformation
