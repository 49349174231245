import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import queryString from "query-string";
import React, { Component } from "react";

import agent from "../../../../agent/agent";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import { DialogConfirmation } from "../../../../components";
import {
    Filter as FilterComponent,
    PopUpDetailProduct as PopUpDetailProductComponent,
    Table as TableComponent
} from "./components";
import DialogSetStore from "./components/DialogSetStore";

const initialFilter = {
    "sortInfo": "-id",
};

const visibleColumns = [
    {
        id: "id",
        label: "№ заказа",
        sortable: true,
        width: 'auto',
    },
    {
        id: "deliveryDate",
        label: "Срок выполнения",
        sortable: true,
        width: 'auto',
    },
    {
        id: "orderItems",
        label: "Состав заказа (кол-во)",
        sortable: false,
        width: 'auto',
    },
    {
        id: "deliveryMethod",
        label: "Способ доставки",
        sortable: false,
        width: 'auto',
    },
    {
        id: "deliveryAddress",
        label: "Адрес доставки",
        sortable: false,
        width: 'auto',
    },
    {
        id: "finalDeliveryPrice",
        label: "Стоимость доставки",
        sortable: true,
        width: 'auto',
    },
    {
        id: "sum",
        label: "Стоимость заказа",
        sortable: true,
        width: 'auto',
    },
    {
        id: "action",
        label: "",
        sortable: false,
        width: 'auto',
    }
];

class ListOrders extends Component {
    constructor(props) {
        super( props );

        this.state = {
            orders: [],

            filter: {
                ...initialFilter
            },
            limit: 20,
            pagination: {
                page: 1,
                totalPage: 1
            },

            settings: {},

            stores: [],

            isLoading: true,
            isShowBackdrop: false,
            isOpen: false,
            orderFromDialogSetStore: {},
        };

        this.refPopUpDetailProduct = React.createRef();
        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getSettings();
        await this.getStores();
        await this.getOrders();
    }

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse( locationSearch, {
            arrayFormat: "bracket"
        } );
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState( {
            filter,
            pagination,

            initOpenFilter: Object.keys( parseSearch || {} ).length > 0
        } );
    }

    // Логика получения заказов
    getOrders = async () => {
        this.setState( { isLoading: true } );

        const filter = this.getFilters();
        const { limit } = this.state;
        const {
            orders,
            totalCount
        } = await agent.get( `/orders${ filter }&limit=${ limit }&listing=true&paymentStatus=fullPrepayment` ).then( (res) => {
            return {
                orders: res.data.orders || [],
                totalCount: res.data.count
            }
        } ).catch( (err) => {
            return {
                orders: [],
                totalCount: 0
            }
        } );

        let pagination = { ...this.state.pagination };
        pagination.totalPage = Math.ceil( totalCount / limit ) || 1;

        this.setState( {
            isLoading: false,
            pagination,
            orders
        } );
    }

    getSettings = async () => {
        const settings = await agent.get( `/settings` )
            .then( (res) => res.data.settings )
            .catch( (err) => {
            } );

        this.setState( { settings } );
    }

    getStores = async () => {
        const { stores } = await agent.get( `/stores/get-by-user/${ this.props.user.id }` )
            .then( res => res.data )
            .catch( err => {
                return [];
            } )
        this.setState( {
            stores,
        } )
    }

    // Логика работы с фильтром
    getFilters = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };
        let string = [
            `page=${ pagination.page }`
        ];
        Object.keys( filter ).map( (key) => {
            if (filter[key]) {
                if (key === 'limit') {
                    this.setState( { limit: Number( filter[key] ) } );
                } else {
                    let value = filter[key];
                    string.push( `${ key }=${ value }` );
                }
            }
        } );

        window.history.replaceState( null, null, `/list?${ string.join( "&" ) }&limit=${ this.state.limit }` );

        return `?${ string.join( "&" ) }`
    }

    changeFilter = async (filter, isFastStart) => {

        await this.setState( { filter } );

        if (!isFastStart) {
            return null
        }

        await this.getOrders();

    }
    changePagination = async (pagination) => {
        await this.setState( { pagination } );
        await this.getOrders();
    }
    resetFilter = async () => {
        await this.setState( { filter: { ...initialFilter } } );
        await this.getOrders();
    }

    // Логика принятия заказа в работу
    takeWorkOrder = async (order, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите взять заказ №${ order.id } в работу?`,
                acceptButtonTitle: "Да, взять",
                acceptButtonAction: this.takeWorkOrder.bind( this, order, true )
            } );

            return null
        }

        this.setState( { isShowBackdrop: true } )

        const responsetakeWork = await agent.put( `/orders/${ order.id }/take-as-provider`, {
            storeId: order.storeId,
        } ).then( (res) => {
            return res.data
        } ).catch( (err) => {
            return { error: err.response }
        } );
        if (responsetakeWork.error) {
            this.setState( { isShowBackdrop: false } );

            const errorMessage = Boolean( responsetakeWork.error?.status === 403 ) ?
                "Нет прав" :
                responsetakeWork.error?.data?.message || "Возникла ошибка, попробуйте позднее";

            Notification( {
                type: NotificationTypes.error,
                message: errorMessage
            } );

            return null
        }

        await this.getOrders();

        this.setState( { isShowBackdrop: false } )

        Notification( {
            type: NotificationTypes.success,
            message: `Заказ №${ order.id } взят в работу`
        } );

        window.history.pushState( null, null, '/my-list' );
        this.props.history.push( `/my-list/order/${ order.id }` );
    }

    // Открытие детализации товаров в заказе
    openDetailProducts = (order) => {
        this.refPopUpDetailProduct.current.open( order );
    }

    _routeOrder = (order) => {
        this.props.history.push( `/list/${ order.id }` );
    }

    //Открытие модального окна с выбором склада
    setIsOpen = (isOpen, order) => {
        this.setState( { isOpen: isOpen } );
        this.setState( { orderFromDialogSetStore: order } )
    }
    //Закрытие модального окна
    handleClose = () => {
        this.setIsOpen( false );
        this.setState( { orderFromDialogSetStore: {} } )
    }

    render() {
        const {
            orders,

            filter,
            pagination,

            settings,

            stores,

            isLoading,
            isShowBackdrop,
            isOpen,
            orderFromDialogSetStore,
        } = this.state;

        return (
            <>

                <Box mb={ 4 }>
                    <Typography variant="h1" textAlign="left">Лента заказов</Typography>
                </Box>

                <FilterComponent
                    filter={ filter }
                />

                <TableComponent
                    data={ orders }
                    filter={ filter }
                    pagination={ pagination }
                    settings={ settings }
                    visibleColumns={ visibleColumns }


                    onChangePagination={ this.changePagination }
                    onOpenDetailProducts={ this.openDetailProducts }
                    onChangeFilter={ this.changeFilter }
                    onTakeWorkOrder={ this.takeWorkOrder }
                    openModal={ this.setIsOpen }

                    routeOrder={ this._routeOrder }
                />


                <PopUpDetailProductComponent
                    ref={ this.refPopUpDetailProduct }
                />

                <DialogSetStore
                    stores={ stores }
                    handleClose={ this.handleClose }
                    isOpen={ isOpen }
                    takeWorkOrder={ this.takeWorkOrder }
                    order={ orderFromDialogSetStore }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </>
        );
    }
}

export default ListOrders
