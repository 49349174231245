import React from 'react';
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { formatPhone } from "../../../../../../helper/formatPhone";
import { makeStyles } from "@mui/styles";

const TableComponent = (props) => {
    const {
        drivers,
        isLoading
    } = props;
    const classes = useStyles();
    return (
        <Box className={ classes.root }>
            <Typography className={ classes.title }>
                Водители экспедитора
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№ водителя</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Телефон</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { isLoading
                        ? <>
                            { [ 1, 2, 3, 4, 5 ].map( (item, i) => (
                                <TableRow key={ `skeleton-driver-table-${ i }` }>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                    <TableCell><Skeleton/></TableCell>
                                </TableRow>
                            ) ) }
                        </>
                        : <>
                            { drivers.length > 0
                                ? <>
                                    { drivers.map( driver => (
                                        <TableRow>
                                            <TableCell>{ driver.id }</TableCell>
                                            <TableCell>{ driver.lastName }</TableCell>
                                            <TableCell>{ driver.firstName }</TableCell>
                                            <TableCell>{ formatPhone( driver.phone ) }</TableCell>
                                        </TableRow>
                                    ) ) }
                                </>
                                : <>
                                    <TableRow>
                                        <TableCell
                                            style={ {
                                                borderRadius: "0 0 10px 10px",
                                            } }
                                            align="center"
                                            colSpan={ 4 }
                                        >
                                            У экспедитора нет водителей
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                        </>
                    }
                </TableBody>
            </Table>
        </Box>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 32
    },
} );

export default TableComponent;
