// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import FavoriteExpeditor from './FavoriteExpeditor';

export default compose(
    connect(
        state => ( {
            user: state?.global?.user || {},
        } ),
        dispatch => ( {} ),
    ),
)( FavoriteExpeditor );
