import React from "react";
import { Redirect, Switch } from "react-router-dom";

import { RouteWithLayout } from "../components";
import { Default as DefaultLayout } from "../layouts";
import {
    Accreditation as AccreditationPage,
    FavoriteExpeditor as FavoriteExpeditorPage,
    FavoritesExpeditors as FavoritesExpeditorsPage,
    ListOrder as ListOrderPage,
    ListOrders as ListOrdersPage,
    MyOrder as MyOrderPage,
    MyOrders as MyOrdersPage,
    MyStore as MyStorePage,
    MyStores as MyStoresPage,
    Profile as ProfilePage,
    Rules as RulesPage,
    Wallet as WalletPage,
} from "../pages";
import { delegatedOrderPage } from "./DelegatedOrderRouter";

const pages = [

    {
        path: '/list',
        component: ListOrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/list/:id',
        component: ListOrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/my-list',
        component: MyOrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-list/order/:id',
        component: MyOrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/profile',
        component: ProfilePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/rules',
        component: RulesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/accreditation',
        component: AccreditationPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/my-stores',
        component: MyStoresPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-stores/:id',
        component: MyStorePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/wallet',
        component: WalletPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/delivery/favorites-expeditors',
        component: FavoritesExpeditorsPage,
        layout: DefaultLayout,
        rules: [],
        exact: true,
    },

    {
        path: '/delivery/favorites-expeditors/:id',
        component: FavoriteExpeditorPage,
        layout: DefaultLayout,
        rules: [],
        exact: true,
    },
    delegatedOrderPage
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map( (page, idx) => (
                    <RouteWithLayout
                        key={ 'page-' + idx }
                        { ...page }
                    />
                ) )
            }

            <Redirect to="/list"/>
        </Switch>
    );
};

export default MainRoutes;
