import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { formatPhone } from "../../../../../../helper/formatPhone";

const MainInformation = (props) => {
    const {
        expeditor
    } = props;
    const classes = useStyles();
    return (
        <Box className={ classes.root }>
            <Typography className={ classes.title }>
                Информация об экспедиторе
            </Typography>
            <Box className={ classes.content }>
                <Box className={ classes.rowContent }>
                    <Typography className={ classes.towLabel }>№ экспедитора</Typography>
                    <Typography className={ classes.towValue }>
                        { expeditor.id }
                    </Typography>
                </Box>
                <Box className={ classes.rowContent }>
                    <Typography className={ classes.towLabel }>Фамилия</Typography>
                    <Typography className={ classes.towValue }>
                        { expeditor.lastName }
                    </Typography>
                </Box>
                <Box className={ classes.rowContent }>
                    <Typography className={ classes.towLabel }>Имя</Typography>
                    <Typography className={ classes.towValue }>
                        { expeditor.firstName }
                    </Typography>
                </Box>
                <Box className={ classes.rowContent }>
                    <Typography className={ classes.towLabel }>Телефон</Typography>
                    <Typography className={ classes.towValue }>
                        <Tooltip title="Позвонить" arrow>
                            <Link href={ `tel:+${ expeditor.phone }` }>
                                { formatPhone( expeditor.phone ) }
                            </Link>
                        </Tooltip>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 32
    },
    content: {
        display: "flex",
        flexDirection: 'column',
    },

    rowContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 8,
        "&:first-child": {
            marginTop: 0,
        },
    },

    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,
        flex: 1.5,
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
        flex: 10.5,
    },
} );

export default MainInformation;
