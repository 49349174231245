import React from "react";
import { Dialog, DialogContent, Grid, Typography, } from "@mui/material";

class PopUpDetailProduct extends React.PureComponent {
    constructor(props) {
        super( props );

        this.state = {
            order: {},

            isOpen: false,
        }
    }

    open = (order) => {

        this.setState( {
            order: order,
            isOpen: true
        } )

    }
    close = () => {
        this.setState( {
            isOpen: false
        } )
    }


    render() {
        const {
            order,
            isOpen
        } = this.state;

        return (
            <>

                <Dialog
                    open={ isOpen }

                    maxWidth="sm"
                    fullWidth

                    onClose={ this.close }
                >

                    <DialogContent>
                        <Grid container spacing={ 1 }>
                            { ( order.orderItems || [] ).map( (product) => (
                                <Grid item xs={ 12 }>
                                    <Typography>
                                        { product?.productName }
                                    </Typography>
                                </Grid>
                            ) ) }
                        </Grid>
                    </DialogContent>

                </Dialog>

            </>
        );
    }
}

export default PopUpDetailProduct
