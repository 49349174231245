import React from "react";
import {
    NotificationUserNotActive as NotificationUserNotActiveComponent,
    Sidebar as SidebarComponent
} from "./components";
import { Box } from "@mui/material";
import { compose } from "recompose";
import { connect } from "react-redux";

class Default extends React.Component {

    render() {
        const {
            user
        } = this.props;

        return (
            <Box display="flex" height="100vh">

                <SidebarComponent/>

                <Box px={ 4 } py={ 2 } width="100%">
                    { this.props.children }
                </Box>


                <NotificationUserNotActiveComponent
                    user={ user }
                />

            </Box>
        )
    }
}

export default compose(
    connect(
        state => ( {
            user: state.global.user || {}
        } ),
        dispatch => ( {} ),
    ),
)( Default )
