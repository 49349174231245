import React, { Component } from "react";
import { Box, Container, Typography } from "@mui/material";
import axios from "axios";

class Rules extends Component {
    constructor(props) {
        super( props );

        this.state = {
            rules: {}
        };
    }

    componentDidMount = async () => {
        await this.getContent();
    }

    getContent = async () => {
        const rules = await axios.get( 'https://pilorami.online/wp-json/wp/v2/pages?slug=rules' ).then( (res) => {
            return res.data?.[0]
        } )

        this.setState( {
            rules
        } )
    }

    render() {
        const {
            rules
        } = this.state;

        return (
            <Container>

                <Box mb={ 2 }>
                    <Typography variant="h1">
                        { rules?.title?.rendered }
                    </Typography>
                </Box>

                <Box px={ 4 } py={ 3 } borderRadius={ 2 } mx="auto" bgcolor="white" className="box-shadow">
                    <Typography className="wordpress-block"
                                dangerouslySetInnerHTML={ { __html: rules?.content?.rendered } }/>
                </Box>

            </Container>
        );
    }
}

export default Rules
