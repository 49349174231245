import React, { Component, createRef } from 'react';
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { TableComponent } from './components';
import agent from "../../../../agent/agent";
import { DialogConfirmation } from "../../../../components";
import { Notification, NotificationTypes } from '../../../../common/Notification';

class FavoritesExpeditors extends Component {
    constructor(props) {
        super( props );
        this.state = {
            favoritesExpeditors: [],
            isLoading: false,
            isShowBackdrop: false,
        };

        this.refDialogConfirmation = createRef();
    }

    componentDidMount = async () => {
        await this.getFavoritesExpeditors();
    };

    getFavoritesExpeditors = async () => {
        this.setState( { isLoading: true } );
        const response = await agent.get( `/favorite-expeditor/get-by-provider` )
            .then( res => res.data.expeditors )
            .catch( err => [] );

        this.setState( {
            favoritesExpeditors: response,
            isLoading: false,
        } );
    };

    deleteFavoriteExpeditor = async (expeditorId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите удалить экспедитора из списка избранных?",
                acceptButtonTitle: "Да, удалить",
                acceptButtonAction: this.deleteFavoriteExpeditor.bind( this, expeditorId, true ),
            } );

            return null;
        }

        this.setState( {
            isShowBackdrop: true,
        } )

        const response = await agent.delete( `/favorite-expeditor/${ expeditorId }`, {
            data: {
                expeditorId,
                providerId: this.props.user.id,
            }
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( {
                isShowBackdrop: false,
            } )
            Notification( {
                message: response.error?.data?.message || "Ошибка при удалении экспедитора из списка избранных",
                type: NotificationTypes.error,
            } );

            return null;
        }

        await this.getFavoritesExpeditors();
        this.setState( {
            isShowBackdrop: false,
        } );

        Notification( {
            message: "Экспедитор успешно удален из списка избранных",
            type: NotificationTypes.success,
        } )
    };

    _routeExpeditor = (expeditorId) => {
        this.props.history.push( `/delivery/favorites-expeditors/${ expeditorId }` );
    };

    render() {
        const {
            favoritesExpeditors,
            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>
                <Box mb={ 4 }>
                    <Typography variant="h1" textAlign="left">Избранные экспедиторы</Typography>
                </Box>

                <TableComponent
                    expeditors={ favoritesExpeditors }
                    isLoading={ isLoading }
                    onDeleteExpeditor={ this.deleteFavoriteExpeditor }
                    onRouteExpeditor={ this._routeExpeditor }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

export default FavoritesExpeditors;
